import React, { useState, Fragment } from 'react'

export default function StatusField({ fieldName, selectableEntity }) {
  // eslint-disable-next-line
  const [value, setValue] = useState(selectableEntity.name)
  return (
    <Fragment>
      <b>{fieldName}: </b>
      {value}
    </Fragment>
  )
}
