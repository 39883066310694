import BigNumber from 'bignumber.js';
BigNumber.set({
    FORMAT: {
        decimalSeparator: '.',
        groupSeparator: ' ',
    },
});
var setCurrencyToApi = function (value) {
    var number = new BigNumber(value);
    var answer = number.times(100);
    return answer.dp(0).toNumber();
};
var getPLNFromApi = function (value) {
    var number = new BigNumber(value);
    var answer = number.div(100);
    return answer.toFormat(2) + " z\u0142";
};
var getCurrencyNumberFromApi = function (value) {
    var number = new BigNumber(value);
    var answer = number.div(100);
    return answer.dp(2).toNumber();
};
export { setCurrencyToApi, getCurrencyNumberFromApi, getPLNFromApi };
