import { connect } from 'react-redux'
import { updateQuantity, checkout, removeCartItem } from '@ui/shared'
import CartComponentContent from './../pages/Home/components/CartComponentContent'

const mapStateToProps = (state, ownProps) => {
  return {
    cart: state.shop.cart,
    totalPrice: state.shop.totalPrice,
    activeStep: ownProps.activeStep,
    setActiveStep: ownProps.setActiveStep,
    cartDetails: ownProps.cartDetails,
    isLastStep: ownProps.isLastStep,
    formValues: ownProps.formValues,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuantity: (cartItem, index, quantity) =>
      dispatch(updateQuantity(cartItem, index, quantity)),
    checkout: () => dispatch(checkout()),
    removeCartItem: (index) => dispatch(removeCartItem(index)),
  }
}

const CartContainerStep2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartComponentContent)

export default CartContainerStep2
