import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
var useStyles = makeStyles(function (theme) { return ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    center: {
        alignItems: 'center',
        justifyContent: 'center',
    },
}); });
export var Loader = function (_a) {
    var align = _a.align;
    var classes = useStyles();
    return (React.createElement("div", { className: "" + (classes.root + ' ' + (align === 'center' ? ' ' + classes.center : '')) },
        React.createElement(CircularProgress, { size: "2rem", color: "secondary" })));
};
