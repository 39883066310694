import { schemaConstants } from './../_constants';
export var schemaReducer = function (state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case schemaConstants.SCHEMA_SUCCESS:
            return action.payload.schema;
        case schemaConstants.SCHEMA_FAILURE:
        case schemaConstants.SCHEMA_ERASE:
            return null;
        default:
            return state;
    }
};
