export var get = function (object, path, value) {
    var pathArray = Array.isArray(path)
        ? path
        : path.split('.').filter(function (key) { return key; });
    var pathArrayFlat = pathArray.flatMap(function (part) {
        return typeof part === 'string' ? part.split('.') : part;
    });
    var checkValue = pathArrayFlat.reduce(function (obj, key) { return obj && obj[key]; }, object);
    return checkValue === undefined ? value : checkValue;
};
