import { fetchData } from './fetchData';
import { handleAuthError } from './handleAuthError';
import { processError } from './processError';
export var fetchDataHandleAuthError = function (url, method, request, handleSuccess, handleError, dispatch, action) {
    if (method === void 0) { method = 'GET'; }
    if (request === void 0) { request = {}; }
    if (handleSuccess === void 0) { handleSuccess = null; }
    if (handleError === void 0) { handleError = null; }
    fetchData(url, method, request, null, false).then(function (response) { return handleSuccess(response); }, function (error) {
        error = processError(error);
        handleAuthError.default(dispatch, action, error.response);
        handleError(error);
    });
};
