import React from 'react'
import { useDispatch } from 'react-redux'
import { authHeader } from '@ui/shared'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ItemComponent from './ItemComponent'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  alert: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
})

function ShopComponent(props) {
  const { classes, inventory, formValues, addToCart } = props

  const dispatch = useDispatch()

  const period = `${moment(formValues.startDate).format(
    'DD.MM.YYYY'
  )}..${moment(formValues.endDate).format('DD.MM.YYYY')}`

  const rentalPlace = formValues.sourceRentalPlace?.id

  function getData() {
    return (dispatch) => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}/cars/availableForHire?rentalPlace=${rentalPlace}&period=${period}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            type: 'FETCH_DATA',
            inventory: res,
          })
        })
    }
  }

  React.useEffect(() => {
    rentalPlace && dispatch(getData())
    // eslint-disable-next-line
  }, [rentalPlace, period])

  React.useEffect(() => {
    dispatch({
      type: 'SET_DAYS',
      daysOfRent: moment
        .duration(
          moment(formValues.endDate)
            .startOf('day')
            .diff(moment(formValues.startDate).startOf('day'))
        )
        .asDays(),
    })
    // eslint-disable-next-line
  }, [period])

  return (
    <div className={classes.root}>
      <Grid container spacing={16}>
        {inventory?.content.length === 0 ? (
          <Alert
            variant="outlined"
            severity="warning"
            className={classes.alert}
          >
            Brak pojazdów w tym terminie/miejscu
          </Alert>
        ) : (
          <Alert variant="outlined" severity="info" className={classes.alert}>
            Wybierz pojazd i przejdź do koszyka
          </Alert>
        )}
        {inventory?.content.map((item) => (
          <ItemComponent item={item} addToCart={addToCart} key={item.id} />
        ))}
      </Grid>
    </div>
  )
}

ShopComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  inventory: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
}

export default withStyles(styles)(ShopComponent)
