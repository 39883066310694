import React, { useState } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Collapse from '@material-ui/core/Collapse'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Button from '@material-ui/core/Button'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { get } from '@ui/shared'
import { polishPlural } from '@ui/shared'
import StatusField from './../../pages/Leases/Lease/fields/StatusField'
import { hireStatus } from 'dataconfig'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    padding: '9px',
  },
  paragraph: {
    margin: '5px',
  },
  paragraphWide: {
    margin: '5px',
    gridColumn: '-1/1',
  },
  align: {
    verticalAlign: 'middle',
  },
  cursor: {
    cursor: 'pointer',
    padding: '0',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableHC: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  tableRC: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  tableWrap: {
    overflow: 'auto',
  },
  padNone: {
    padding: 0,
  },
  marL: {
    marginLeft: '5px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  img: {
    width: '252px',
  },
}))

export default function TableList({
  data,
  isLoading,
  error,
  mainData,
  expandData,
  setBreakOpen,
  setBreakId,
  colWidth,
  dataLength,
  link,
  pagination,
  queryParams,
  setQueryParams = () => {},
  setCar,
}) {
  const classes = useStyles()
  const initialState = {
    collapsedRow: null,
  }
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)
  const [collapse, setCollapse] = useState(initialState)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  const handleExpandClick = (id) => {
    const isOpen = collapse.collapsedRow

    setCollapse({
      collapsedRow: isOpen ? null : id,
    })
  }

  const handleOpen = (e, row, b) => {
    setCar(row ? row : {})
    setBreakOpen(true)
    setBreakId(b)
  }

  return (
    <React.Fragment>
      <div className={classes.tableWrap}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {expandData && <TableCell key={'thc-id'}></TableCell>}
              {mainData.map((x, i) => (
                <TableCell key={`thc-${i}`}>
                  <div className={classes.tableHC}>
                    <span>{x.name}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading &&
              [...Array(rowsPerPage)].map((i, key) => (
                <TableRow key={key}>
                  <TableCell colSpan={10}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))}
            {error && (
              <TableRow>
                <TableCell colSpan={10} style={{ padding: 0 }}>
                  <Alert severity="error">
                    wystąpił błąd podczas pobierania danych
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              data &&
              data.map((row, index) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      {expandData && (
                        <TableCell
                          className={classes.cursor}
                          onClick={() => handleExpandClick(row.id)}
                        >
                          {collapse.collapsedRow === row.id ? (
                            <ArrowDownwardIcon
                              className={classes.align}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowForwardIcon
                              className={classes.align}
                              fontSize="small"
                            />
                          )}
                        </TableCell>
                      )}
                      {mainData.map((e, i) => {
                        if (e.prop === mainData[0].prop) {
                          return (
                            <TableCell key={i} className={classes.cursor}>
                              {link ? (
                                <Button
                                  component={Link}
                                  color="primary"
                                  to={`${link}/${row.id}`}
                                >
                                  {get(row, mainData[0].prop, 'nothing')}
                                </Button>
                              ) : (
                                get(row, mainData[0].prop, 'nothing')
                              )}
                            </TableCell>
                          )
                        } else {
                          if (e.prop === 'hiredItems.length') {
                            const car = get(row, e.prop, 'nothing')
                            return (
                              <TableCell key={i} className={classes.tableRC}>
                                {car}
                              </TableCell>
                            )
                          }
                          if (e.prop === 'hiredItems') {
                            const hiredItems = get(row, e.prop, 'nothing')

                            const breakdownsSum = hiredItems
                              .map((a) => a.breakdowns.length)
                              .reduce((a, b) => a + b)

                            return (
                              <TableCell className={classes.tableRC}>
                                {breakdownsSum}
                              </TableCell>
                            )
                          }

                          if (e.prop === 'startDate' || e.prop === 'endDate') {
                            return (
                              <TableCell key={i}>
                                <Moment format="DD.MM.YYYY">
                                  {get(row, e.prop, 'nothing')}
                                </Moment>
                              </TableCell>
                            )
                          }
                          return (
                            <TableCell key={i}>
                              {get(row, e.prop, 'nothing')}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>

                    {expandData && (
                      <TableRow>
                        <TableCell className={classes.padNone} colSpan={10}>
                          <Collapse
                            in={collapse.collapsedRow === row.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <div
                              className={classes.grid}
                              style={{
                                gridTemplateColumns: `repeat(auto-fit, minmax(${colWidth}, 1fr))`,
                              }}
                            >
                              {expandData.map((e, i) => {
                                if (
                                  e.prop === 'car.photos' &&
                                  row.car?.photos[0]?.url
                                ) {
                                  return (
                                    <div className={classes.paragraphWide}>
                                      <img
                                        className={classes.img}
                                        src={`${process.env.REACT_APP_API_ENTRYPOINT}${row.car?.photos[0]?.url}`}
                                        alt="car"
                                      />
                                    </div>
                                  )
                                }
                                if (
                                  e.prop === 'canStatistics.engineWorkSeconds'
                                ) {
                                  return (
                                    <p key={i} className={classes.paragraph}>
                                      <b>{e.name}: </b>
                                      <span>
                                        {Math.floor(
                                          get(row, e.prop, '') / (60 * 60)
                                        )}{' '}
                                        h
                                      </span>
                                    </p>
                                  )
                                } else {
                                  return (
                                    <p key={i} className={classes.paragraph}>
                                      <b>{e.name}: </b>
                                      {get(row, e.prop, 'nothing')}
                                    </p>
                                  )
                                }
                              })}

                              {row?.hireStatus && (
                                <div className={classes.paragraph}>
                                  <StatusField
                                    selectableEntity={hireStatus.find(
                                      (el) => el.id === row?.hireStatus
                                    )}
                                    fieldName={'Status'}
                                  />
                                </div>
                              )}
                              {row?.hiredItems && (
                                <div>
                                  {row?.hiredItems?.map((item, index) => {
                                    return (
                                      <div
                                        key={`hi-${index}`}
                                        className={classes.paragraphWide}
                                      >
                                        {item?.car.fullName}
                                        {item?.breakdowns.length > 0 && (
                                          <div>
                                            {item?.breakdowns.length}
                                            {polishPlural(
                                              ' zgłoszenie ',
                                              ' zgłoszenia ',
                                              ' zgłoszeń ',
                                              item?.breakdowns.length
                                            )}
                                          </div>
                                        )}
                                        {item?.canAlerts.length > 0 && (
                                          <div>
                                            {item?.canAlerts.length}
                                            {polishPlural(
                                              ' nieprawidłowośc',
                                              ' nieprawidłowości',
                                              ' nieprawidłowości',
                                              item?.canAlerts.length
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                              {row?.optionalAccessories?.length > 0 && (
                                <p className={classes.paragraphWide}>
                                  <b>Wyposażenie dodatkowe: </b>
                                  {row?.optionalAccessories?.map(
                                    (oa, index) => (
                                      <span>
                                        {oa?.name}
                                        {index <
                                        row?.optionalAccessories.length - 1
                                          ? ', '
                                          : ''}
                                      </span>
                                    )
                                  )}
                                </p>
                              )}
                              {row?.canAlerts?.length > 0 && (
                                <p className={classes.paragraphWide}>
                                  <b>Nieprawidłowości: </b>
                                  {row?.canAlerts
                                    ?.slice(0, 2)
                                    .map((pa, index) => (
                                      <span>
                                        {pa?.message}
                                        {index < row?.canAlerts.length - 1
                                          ? ', '
                                          : ''}
                                      </span>
                                    ))}
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    className={classes.marL}
                                    onClick={(e) =>
                                      handleOpen(e, row, 'alerts')
                                    }
                                  >
                                    {row?.canAlerts.length - 2} więcej...
                                  </Button>
                                </p>
                              )}

                              {row?.breakdowns && (
                                <p className={classes.paragraphWide}>
                                  <b>Zgłoszenia awarii / Kolizji: </b>
                                  {row?.breakdowns?.map((b, i) => {
                                    return (
                                      <Button
                                        onClick={(e) => handleOpen(e, row, b)}
                                        color="primary"
                                        variant="outlined"
                                        className={classes.marL}
                                        key={i}
                                      >
                                        Awaria/Kolizja nr {i + 1}
                                      </Button>
                                    )
                                  })}
                                  {row?.hireItemStatus === 'hireItem:Hire' && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className={classes.marL}
                                      onClick={(e) => handleOpen(e, row)}
                                    >
                                      Dodaj zgłoszenie
                                    </Button>
                                  )}
                                </p>
                              )}
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
          </TableBody>
        </Table>
      </div>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage="Pokaż na stronie:"
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  )
}
