import { store } from 'react-notifications-component';
export var notification = function (type, message, title, duration) {
    if (title === void 0) { title = null; }
    store.addNotification({
        type: type === 'error' ? 'danger' : type,
        title: title
            ? title.toString()
            : type.charAt(0).toUpperCase(0) + type.slice(1),
        message: message,
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: duration || 5000,
            onScreen: true,
        },
    });
};
