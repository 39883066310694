export var excludeInnerNullObjects = function (outerObject) {
    var toReturnObject = {};
    for (var _i = 0, _a = Object.entries(outerObject); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], innerObject = _b[1];
        if (innerObject !== null) {
            toReturnObject[key] = innerObject;
        }
    }
    return toReturnObject;
};
