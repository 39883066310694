import { amber, blue } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

const dark = {
  palette: {
    type: 'dark',
    primary: {
      main: amber[500],
      contrastText: '#000',
    },
    secondary: {
      main: blue[500],
      contrastText: '#fff',
    },
  },
}

const light = {
  palette: {
    type: 'light',
    primary: {
      main: '#282944',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fdb101',
      contrastText: '#000',
    },
  },
}

export const theme = (type) => createMuiTheme(type === 'dark' ? dark : light)
