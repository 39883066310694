import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const styles = (theme) => ({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'contain',
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  wrapper: {
    marginTop: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
})

function ItemComponent(props) {
  const { classes, item, addToCart } = props
  const plns = (item.dailyPrice / 100).toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  })
  return (
    <Grid item xs={12} className={classes.wrapper}>
      <Card className={classes.card} key={item.id}>
        <CardMedia
          className={classes.media}
          image={`${process.env.REACT_APP_API_ENTRYPOINT}${item.photos[0]?.url}`}
          title={item.fullName}
        />
        <CardContent>
          <span>segment {item.segment?.name}</span>
          <Typography gutterBottom variant="headline" component="h2">
            {item.carSerie.model.brand.name} {item.carSerie.model.name}
          </Typography>

          <div className={classes.grid}>
            {' '}
            {item.pernamentAccessories.map((e, i) => (
              <span key={`pa-${i}`}>{e.name}</span>
            ))}
          </div>
          <p>skrzynia {item.carSerie.gearBox?.name}</p>
          <p>{item.carSerie.seatsAmount?.name} drzwiowy</p>
          <p>{item.carSerie.engineFuel?.name}</p>
          <Typography component="p">Cena: {plns}</Typography>
          <p>wersja {item.carSerie?.name}</p>
          <p>
            spalanie w cyklu mieszanym{' '}
            {item.carSerie.fuelConsumptionMixed?.name}
          </p>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            className={classes.button}
            onClick={() => addToCart(item)}
          >
            Zarezerwuj
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

ItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
}

export default withStyles(styles)(ItemComponent)
