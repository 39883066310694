import { authConstants } from './../_constants';
import { authService } from './../_services';
import { notification } from './../_helpers/notification';
import { tokenRefresh } from './../_helpers/tokenRefresh';
import { processError } from './../_helpers/processError';
var login = function (email, password) { return function (dispatch, getState) {
    dispatch({ type: authConstants.LOGIN_REQUEST, payload: { email: email } });
    return authService.login(email, password).then(function (token) {
        dispatch({
            type: authConstants.LOGIN_SUCCESS,
            payload: { token: token },
        });
        tokenRefresh.start(dispatch, getState, logout, refresh);
    }, function (error) {
        dispatch({ type: authConstants.LOGIN_FAILURE });
        notification('error', error.toString());
    });
}; };
var refresh = function () { return function (dispatch) {
    dispatch({ type: authConstants.REFRESH_REQUEST });
    return authService.refresh().then(function (token) {
        dispatch({
            type: authConstants.REFRESH_SUCCESS,
            payload: { token: token },
        });
    }, function (error) {
        dispatch({
            type: authConstants.REFRESH_FAILURE,
            payload: { error: processError(error).response },
        });
        tokenRefresh.clear();
    });
}; };
var restore = function (token) { return function (dispatch, getState) {
    dispatch({
        type: authConstants.LOGIN_RESTORE,
        payload: { token: token },
    });
    tokenRefresh.start(dispatch, getState, logout, refresh);
}; };
var logout = function () { return function (dispatch) {
    dispatch({ type: authConstants.LOGOUT });
    tokenRefresh.clear();
}; };
export var authActions = {
    login: login,
    refresh: refresh,
    restore: restore,
    logout: logout,
};
