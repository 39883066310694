var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ADD_TO_CART, REMOVE_CART_ITEM, UPDATE_QUANTITY, UPDATE_DATE, RESET_SHOP, } from './../_actions/shop.actions';
import { notification } from './../_helpers/notification';
var initialState = {
    cart: [],
    totalPrice: 0,
};
var calculateTotalPrice = function (cart, state) {
    var totalPrice = 0;
    process.env.REACT_APP_TYPE === 'cars'
        ? (totalPrice = cart.reduce(function (totalPrice, cartItem) {
            return totalPrice + cartItem.price * state.daysOfRent + cartItem.bail;
        }, 0))
        : (totalPrice = cart.reduce(function (totalPrice, cartItem) {
            return totalPrice + cartItem.price * cartItem.daysOfRent + cartItem.bail;
        }, 0));
    return totalPrice;
};
var removeCartItem = function (state, index) {
    var cart = __spreadArrays(state.cart.slice(0, index), state.cart.slice(index + 1));
    var totalPrice = calculateTotalPrice(cart, state);
    return __assign(__assign({}, state), { cart: cart,
        totalPrice: totalPrice });
};
var updateQuantity = function (state, item, quantity, index) {
    var cart = __spreadArrays(state.cart);
    if (typeof quantity !== 'undefined' && quantity > 0) {
        item.quantity = quantity;
        var optionsSum = Array.prototype.reduce.call(item.options, function (sum, el) {
            return el.checked ? sum + +el.price : sum;
        }, 0);
        var optionsSum2 = Array.prototype.reduce.call(item.options2, function (sum, el) {
            return el.checked ? sum + +el.price : sum;
        }, 0);
        item.price = item.quantity * (item.unitPrice + optionsSum + optionsSum2);
        item.bail = item.quantity * item.unitBail;
        cart[index] = item;
        var totalPrice = calculateTotalPrice(cart, state);
        return __assign(__assign({}, state), { cart: cart,
            totalPrice: totalPrice });
    }
    else {
        return removeCartItem(state, index);
    }
};
var updateDate = function (state, item, startDate, endDate, daysOfRent, index) {
    var cart = __spreadArrays(state.cart);
    item.startDate = startDate;
    item.endDate = endDate;
    item.daysOfRent = daysOfRent;
    cart[index] = item;
    var totalPrice = calculateTotalPrice(cart, state);
    return __assign(__assign({}, state), { cart: cart,
        totalPrice: totalPrice });
};
var addToCart = function (state, item) {
    var _a, _b;
    var foundItems = state.cart.filter(function (cartItem) {
        return cartItem.id === item.id;
    });
    if (foundItems.length > 0) {
        process.env.REACT_APP_TYPE === 'fitness' &&
            notification('info', 'Już zarezerwowano, zakres można zmienić w koszyku');
        var cart = __spreadArrays(state.cart);
        var totalPrice = calculateTotalPrice(cart, state);
        return __assign(__assign({}, state), { cart: cart,
            totalPrice: totalPrice });
    }
    else {
        var newItem = void 0;
        process.env.REACT_APP_TYPE === 'cars'
            ? (newItem = {
                id: item.id,
                quantity: 1,
                price: item.dailyPrice,
                unitPrice: item.dailyPrice,
                name: item.carSerie.model.name,
                pictureURL: (_a = item.photos[0]) === null || _a === void 0 ? void 0 : _a.url,
                options: item.optionalAccessories,
                options2: item.optionalServices,
                bail: item.segment.bail,
                unitBail: item.segment.bail,
            })
            : (newItem = {
                id: item.id,
                quantity: 1,
                price: item.dailyPrice,
                unitPrice: item.dailyPrice,
                name: item.model.name,
                pictureURL: (_b = item.photos[0]) === null || _b === void 0 ? void 0 : _b.url,
                bail: item.segment.bail,
                unitBail: item.segment.bail,
                startDate: item.startDate,
                endDate: item.endDate,
                dateRanges: item.dateRanges,
                daysOfRent: item.daysOfRent,
            });
        var cart = __spreadArrays(state.cart, [newItem]);
        var totalPrice = calculateTotalPrice(cart, state);
        notification('success', 'Dodano do koszyka', process.env.REACT_APP_TYPE === 'cars'
            ? item.carSerie.model.name
            : item.model.name, 500);
        return __assign(__assign({}, state), { cart: cart,
            totalPrice: totalPrice });
    }
};
export var shopReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var cart;
    switch (action.type) {
        case ADD_TO_CART:
            return addToCart(state, action.item, -1);
        case REMOVE_CART_ITEM:
            return removeCartItem(state, action.index);
        case UPDATE_QUANTITY:
            cart = __spreadArrays(state.cart);
            return updateQuantity(state, cart[action.index], action.quantity, action.index);
        case UPDATE_DATE:
            cart = __spreadArrays(state.cart);
            return updateDate(state, cart[action.index], action.startDate, action.endDate, action.daysOfRent, action.index);
        case RESET_SHOP:
            console.log('INSIDE RESET SHOP');
            return initialState;
        case 'FETCH_DATA':
            return __assign(__assign({}, state), { inventory: action.inventory });
        case 'SET_DAYS':
            return __assign(__assign({}, state), { daysOfRent: action.daysOfRent });
        default:
            return state;
    }
};
