import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import CartItemComponent from './CartItemComponent'
import Snackbar from '@material-ui/core/Snackbar'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { authHeader } from '@ui/shared'
import { Alert } from '@material-ui/lab'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appFrame: {
    height: 430,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    width: `calc(100% - 200px)`,
  },
  'appBar-right': {
    marginRight: 200,
  },
  drawerPaper: {
    position: 'relative',
    width: '100%',
    marginTop: 10,
    borderLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  chip: {
    marginTop: 10,
    marginBottom: 10,
  },

  totalTitle: {
    marginTop: 5,
    width: '100%',
    textAlign: 'center',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
})

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

function CartComponentContent(props) {
  const initialState = {
    modalOpen: false,
    snackBarOpen: false,
  }
  const [state, setState] = React.useState(initialState)

  const handleModalOpen = () => {
    if (props.totalPrice < 1) {
      setState({ snackBarOpen: true })
    } else {
      setState({ modalOpen: true })
    }
  }

  const handleModalClose = () => {
    setState({ modalOpen: false })
  }

  const handleSnackBarClose = () => {
    setState({ snackBarOpen: false })
  }

  const {
    classes,
    cart,
    totalPrice,
    updateQuantity,
    removeCartItem,
    activeStep,
    setActiveStep,
    cartDetails,
    isLastStep,
    formValues,
  } = props

  const sourceRentalPlace = formValues?.sourceRentalPlace?.id
  let startDate = formValues?.startDate
  let endDate = formValues?.endDate

  if (startDate === undefined) startDate = new Date()
  if (endDate === undefined) endDate = new Date()

  const cartData = cart.map((e) => {
    return {
      car: `/cars/${e.id}`,
      optionalAccessories: e.options
        .map((x) => x.checked && `/optional_accessories/${x.id}`)
        .filter(Boolean),
      optionalServices: e.options2
        .map((x) => x.checked && `/optional_services/${x.id}`)
        .filter(Boolean),
    }
  })

  const [childState, setChildState] = React.useState(activeStep)

  React.useEffect(() => {
    setActiveStep(childState)
  }, [setActiveStep, childState])

  const handleSubmit = () => {
    setChildState(activeStep + 1)
    setState({ modalOpen: false })
  }
  const [cartViolations, setCartViolations] = React.useState([])
  const handleSubmitFinal = () => {
    setState({ modalOpen: false })

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/car_hires`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        targetRentalPlace: `/car_rental_places/${sourceRentalPlace}`,
        hiredItems: cartData,
        startDate: startDate,
        endDate: endDate,
        sourceRentalPlace: `/car_rental_places/${sourceRentalPlace}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((json) => {
        setCartViolations([])

        setChildState(activeStep + 1)
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setCartViolations(error.violations)
          }
        })
      })
  }

  const discount = useSelector(
    (state) => state.user.profile.carCustomer.discountLevel.discountRate
  )

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
      >
        <div className={!cartDetails && !isLastStep && classes.toolbar} />
        {cartDetails && (
          <Alert variant="outlined" severity="info" className={classes.alert}>
            Wybierz sprzęt i usługi dodatkowe do pojazdów
          </Alert>
        )}
        {cartViolations.length ? (
          <Alert variant="outlined" severity="error" className={classes.alert}>
            {cartViolations.map((e) => `${e.message} `)}
          </Alert>
        ) : (
          ''
        )}
        <Grid container justify="center">
          <Tooltip title="Całkowity koszt" placement="top">
            <Chip
              color="primary"
              className={classes.chip}
              avatar={
                <Avatar>
                  <AttachMoneyIcon />
                </Avatar>
              }
              label={(
                (totalPrice - totalPrice * (discount / 100)) /
                100
              ).toLocaleString('pl-PL', {
                style: 'currency',
                currency: 'PLN',
              })}
            />
          </Tooltip>
        </Grid>

        <Divider />
        <List>
          {cart.map((cartItem, index) => (
            <CartItemComponent
              cartItem={cartItem}
              updateQuantity={updateQuantity}
              removeCartItem={removeCartItem}
              removeCartItemIndex={index}
              key={cartItem.id}
              cartDetails={cartDetails}
              isLastStep={isLastStep}
            />
          ))}
        </List>
        {!isLastStep && (
          <Button onClick={cartDetails ? handleModalOpen : handleSubmit}>
            {cartDetails ? ' ZAREZERWUJ' : 'DALEJ '}
          </Button>
        )}
      </Drawer>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={state.modalOpen}
        onClose={handleModalClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <Grid container justify="center">
            <Typography variant="title" className={classes.totalTitle}>
              Całkowity koszt:{' '}
              {(totalPrice / 100).toLocaleString('pl-PL', {
                style: 'currency',
                currency: 'PLN',
              })}
            </Typography>

            <Button onClick={handleSubmitFinal}>POTWIERDŹ REZERWACJE</Button>
          </Grid>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.snackBarOpen}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Cart is empty!</span>}
      />
    </div>
  )
}

CartComponentContent.propTypes = {
  classes: PropTypes.object.isRequired,
  cart: PropTypes.array.isRequired,
  totalPrice: PropTypes.number.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
}

export default withStyles(styles)(CartComponentContent)
