import React from 'react';
import { Redirect } from 'react-router-dom';
export var generateRoutes = function (routes) {
    return routes.map(function (route, i) {
        var RouteTypeComponent = route.type;
        switch (RouteTypeComponent) {
            case Redirect:
                return (React.createElement(RouteTypeComponent, { key: "redirect-" + i, exact: !!route.exact, from: route.from, to: route.to }));
            default:
                return route.component ? (React.createElement(RouteTypeComponent, { key: [route.component], exact: !!route.exact, path: route.path, component: route.component })) : (React.createElement(RouteTypeComponent, { key: [route.component], exact: !!route.exact, path: route.path, render: route.render }));
        }
    });
};
