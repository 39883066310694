import { tokenExpiration } from './tokenExpiration';
var INTERVAL_PERIOD = 10 * 1000; // 10s
var EXP_LIMIT = 2 * 6 * INTERVAL_PERIOD; // 2mins
var interval = null;
var start = function (dispatch, getState, logout, refresh) {
    if (!interval) {
        interval = setInterval(function () {
            var _a;
            var token = (_a = getState().auth.data) === null || _a === void 0 ? void 0 : _a.token;
            var isRefreshing = !!getState().auth.isRefreshing;
            var exp = token && tokenExpiration(token);
            if (!exp) {
                dispatch(logout());
            }
            else if (Date.now() > exp - EXP_LIMIT && !isRefreshing) {
                dispatch(refresh());
            }
        }, INTERVAL_PERIOD);
    }
};
var clear = function () {
    if (interval) {
        clearInterval(interval);
        interval = null;
    }
};
export var tokenRefresh = {
    start: start,
    clear: clear,
};
