import { useState, useEffect, useRef } from 'react'
const HOOK_SVG =
  'M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'
const HOOK_PATH = new Path2D(HOOK_SVG)
const SCALE = 3
const OFFSET = 11

export function draw(ctx, location) {
  ctx.save()
  ctx.scale(SCALE, SCALE)

  ctx.translate(location.x / SCALE - OFFSET, location.y / SCALE - OFFSET)
  ctx.fill(HOOK_PATH)
  ctx.restore()
}

export function usePersistentState(init, damagePlaces) {
  const [locations, setLocations] = useState(init)

  useEffect(() => {
    damagePlaces &&
      setLocations(
        damagePlaces.map((el) => ({
          createdAt: el.createdAt,
          x: el.x,
          y: el.y,
        }))
      )
  }, [damagePlaces])

  return [locations, setLocations]
}

export function usePersistentCanvas(damagePlaces) {
  const [locations, setLocations] = usePersistentState([], damagePlaces)
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, 679, 446)
    locations.forEach((location) => draw(ctx, location))
  })

  return [locations, setLocations, canvasRef]
}
