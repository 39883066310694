import React from 'react'
import { useSelector } from 'react-redux'
import { authHeader } from '@ui/shared'
import StatusField from './fields/StatusField'
import GalleryField from './fields/GalleryField'
import { StringField } from '@ui/shared'
import { DatePickerField } from '@ui/shared'
import CanvasContainer from './canvas/CanvasContainer'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { hireStatus } from '../../../dataconfig'
import { notification } from '@ui/shared'

const useStyles = makeStyles({
  gridParent: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    borderBottom: '1px solid lightgrey',
    padding: '10px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    borderBottom: '1px solid lightgrey',
    padding: '10px',
    marginBottom: '10px',
  },
  img: {
    width: '312px',
  },
  button: {
    marginTop: '10px',
  },
})
export default function LeaseModal(props) {
  const classes = useStyles()
  const { breakId, customer, car, item } = props
  const auth = useSelector((state) => state.auth)

  const [lease, setLease] = React.useState(null)
  React.useEffect(() => {
    const fetchData = async (slug) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        const result = await response.json()
        setLease(() => result)
      } catch (error) {}
    }
    breakId && fetchData(breakId)
  }, [auth.data.token, breakId])

  const listStatus = hireStatus.filter((el) => el.type === 'hireItem')
  const val = hireStatus.find((el) => el.id === item.hireItemStatus)

  const [updatableLease, setUpdatableLease] = React.useState({})
  const [violations, setViolations] = React.useState([])
  let endPoint
  breakId ? (endPoint = breakId) : (endPoint = '/car_breakdowns')
  const handleSave = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${endPoint}`, {
      method: breakId ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        ...updatableLease,
        hireItem: `/car_hire_items/${item.id}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        setLease(response)
        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(`${e.propertyPath} : ${e.message}`)
            })

            errors = errors.join('\r\n')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <div>
      {car?.photos.length > 0 && (
        <img
          className={classes.img}
          src={`${process.env.REACT_APP_API_ENTRYPOINT}${car?.photos[0]?.url}`}
          alt="car"
        />
      )}
      <Typography variant="h6" gutterBottom>
        Zaznacz kursorem miejsce uszkodzenia na obrazku
      </Typography>

      <CanvasContainer
        type="damagePlaces"
        damagePlaces={lease?.damagePlaces}
        updatableParentEntity={updatableLease}
        setUpdatableParentEntity={setUpdatableLease}
      />

      <div className={classes.gridParent}>
        <div>
          <b>Klient</b>
        </div>
        <div>
          {customer?.personalDetails?.companyName} - {customer?.user?.firstName}{' '}
          {customer?.user?.lastName}
        </div>
      </div>
      <div className={classes.gridParent}>
        <div>
          <b>Pojazd</b>
        </div>
        <div>
          {car?.fullName} ({car?.registrationNumber})
        </div>
      </div>
      <div className={classes.gridParent}>
        <DatePickerField
          fieldName="Czas usterki"
          value={lease?.breakdownDatetime}
          type="breakdownDatetime"
          violations={violations}
          updatableParentEntity={updatableLease}
          setUpdatableParentEntity={setUpdatableLease}
        />{' '}
      </div>
      <div className={classes.gridParent}>
        <DatePickerField
          fieldName="Czas zgłoszenia"
          value={lease?.reportDatetime}
          type="reportDatetime"
          violations={violations}
          updatableParentEntity={updatableLease}
          setUpdatableParentEntity={setUpdatableLease}
        />{' '}
      </div>
      <div className={classes.gridParent}>
        <StringField
          fieldName="Opis uszkodzenia"
          value={lease?.description}
          type="description"
          violations={violations}
          updatableParentEntity={updatableLease}
          setUpdatableParentEntity={setUpdatableLease}
        />{' '}
      </div>

      <div className={classes.gridChild}>
        <StatusField
          selectableEntity={
            val !== undefined
              ? val
              : {
                  id: lease?.status,
                  name: lease?.status,
                  type: 'hireItem',
                }
          }
          listStatus={listStatus}
          leaseId={item.id}
          fieldName={'Status'}
        />
      </div>
      <Typography variant="h6" gutterBottom>
        Pliki najemcy
      </Typography>
      <GalleryField
        type="photos"
        fieldName="Dodaj zdjęcia"
        photos={lease?.photos}
        violations={violations}
        updatableParentEntity={updatableLease}
        setUpdatableParentEntity={setUpdatableLease}
        isPhoto={true}
      />
      <br />
      <GalleryField
        type="files"
        fieldName="Dodaj dokumenty"
        photos={lease?.files}
        violations={violations}
        updatableParentEntity={updatableLease}
        setUpdatableParentEntity={setUpdatableLease}
        isPhoto={false}
      />
      <br />
      <Button
        className={classes.button}
        onClick={handleSave}
        variant="contained"
        color="primary"
      >
        Zapisz
      </Button>
    </div>
  )
}
