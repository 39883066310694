import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import { useSelector, useDispatch } from 'react-redux'
import { validate } from 'email-validator'
import { authActions } from '@ui/shared'
import { Login } from '@ui/shared'
import { Box, Typography, Container, CssBaseline } from '@material-ui/core'

import { Copyright } from '@ui/shared'
import { useStyles } from '@ui/shared'

import { Link, useHistory } from 'react-router-dom'
import { Add } from './Add'
import { Activate } from './Activate'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

export const LoginContainer = () => {
  const classes = useStyles()
  const [state, setState] = useState({
    email: '',
    password: '',
    submitted: false,
  })
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((state) => ({ ...state, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setState((state) => ({ ...state, submitted: true }))
    const { email, password } = state

    if (validate(email) && password) {
      dispatch(authActions.login(email, password))
    }
  }

  const isLogging = useSelector((state) => state.auth.isLogging)
  const { email, password, submitted } = state

  const history = useHistory()
  const [value, setValue] = React.useState(
    history.location.pathname.split('/')[1] === 'security' ? 1 : 0
  )

  const handlePageChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <img src="/images/logo.png" alt="logo" />
        </div>
        <Typography component="h1" variant="h5">
          PANEL KLIENTA
        </Typography>

        <Tabs
          value={value}
          onChange={handlePageChange}
          aria-label="nav tabs example"
        >
          <Tab
            component={Link}
            label="Zaloguj się"
            to="/login"
            {...a11yProps(0)}
          />
          <Tab
            component={Link}
            label="Zarejestruj się"
            to={
              history.location.pathname.split('/')[1] === 'security'
                ? '/security/*'
                : '/register'
            }
            {...a11yProps(1)}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Login
            values={{ email, password }}
            handlers={{ handleChange, handleSubmit }}
            status={{ submitted, isLogging }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {history.location.pathname.split('/')[1] === 'security' ? (
            <Activate />
          ) : (
            <Add />
          )}
        </TabPanel>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
