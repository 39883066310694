import { configureStore } from './store';
export var store = configureStore();
store.subscribe(function () {
    var _a;
    if ((_a = store.getState().auth.data) === null || _a === void 0 ? void 0 : _a.token) {
        localStorage.setItem('token', store.getState().auth.data.token);
    }
    else {
        localStorage.removeItem('token');
    }
});
