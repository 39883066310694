import React from 'react'
import moment from 'moment'

export default function DaysLeft({
  fieldName,

  value,
}) {
  let days
  const now = moment(new Date())
  const end = moment(value)
  const duration = moment.duration(now.diff(end))

  days = parseInt(duration.asDays())

  if (days > 0) {
    days = 0
  } else {
    days = Math.abs(days)
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName}`}</b>
      </div>

      <div>{days}</div>
    </React.Fragment>
  )
}
