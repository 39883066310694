var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { userConstants } from './../_constants';
export var userReducer = function (state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case userConstants.PROFILE_SUCCESS:
        case userConstants.PROFILE_UPDATE:
            return {
                profile: __assign(__assign({}, state.profile), action.payload),
            };
        case userConstants.PROFILE_FAILURE:
        case userConstants.PROFILE_ERASE:
            return {};
        default:
            return state;
    }
};
