import React from 'react'
import { useSelector } from 'react-redux'
import Lease from './Lease'
import { authHeader } from '@ui/shared'
import { useFetch } from '@ui/shared'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import LeaseModal from './LeaseModal'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import { DriveEta, ErrorOutline } from '@material-ui/icons'
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    width: '312px',
  },
}))
export const LeaseContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { leaseId },
  } = match

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, leaseId]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.hires}/${leaseId}`,
    options,
    args
  )

  const mainData = [
    {
      name: <DriveEta />,
      prop: 'car.fullName',
    },

    {
      name: <ErrorOutline />,
      prop: 'breakdowns.length',
    },
  ]

  const expandData = [
    {
      name: 'Przejechane w najmie',
      prop: 'canStatistics.distanceRidden',
    },
    {
      name: 'Czas pracy silnika',
      prop: 'canStatistics.engineWorkSeconds',
    },

    {
      name: 'Ecodriving',
      prop: 'canStatistics.lastCanRecord.ecoDrivingMetric',
    },

    {
      name: 'Prędkość pojazdu',
      prop: 'canStatistics.lastCanRecord.speed',
    },
    {
      name: 'Obroty silnika',
      prop: 'canStatistics.lastCanRecord.revolutionsPerMinute',
    },
    {
      name: 'Zdjęcie',
      prop: 'car.photos',
    },
  ]

  const fleet = { ...res.state.data }
  const styles = (theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })
  const [breakOpen, setBreakOpen] = React.useState(false)
  const [breakId, setBreakId] = React.useState({})
  const [car, setCar] = React.useState({})

  const handleClose = () => {
    setBreakOpen(false)
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })
  function DialogSlide() {
    return (
      <div>
        <Dialog
          open={breakOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          fullScreen={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Mój najem nr {res.state.data.id} -{' '}
            {breakId !== 'alerts'
              ? 'zgłoszenie awarii i wypadku'
              : 'wykaz nieprawidłowości'}
          </DialogTitle>
          <DialogContent>
            {breakOpen && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {breakId !== 'alerts' ? (
                    <LeaseModal
                      breakId={breakId}
                      customer={res.state.data.customer}
                      car={car.car ? car.car : {}}
                      item={car}
                    />
                  ) : (
                    <div>
                      {car?.canAlerts?.map((e, i) => {
                        return <p key={`alert-${i}`}>{e?.message}</p>
                      })}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {res.state.data && (
        <>
          <Title>Mój Najem - {leaseId}</Title>
          <Lease
            lease={res.state.data}
            setLease={res.setState}
            header={[
              {
                name: 'STATUS',
                prop: 'hireStatus',
              },
              {
                name: 'OD',
                prop: 'startDate',
              },
              {
                name: 'DO',
                prop: 'endDate',
              },
              {
                name: 'POZOSTAŁO',
                prop: 'daysLeft',
                dataProp: 'endDate',
              },
              {
                name: 'WYPOŻYCZALNIA',
                prop: 'sourceRentalPlace.name',
              },
              {
                name: 'WARTOŚĆ',
                prop: 'cost',
              },
              {
                name: 'FAKTURA',
                prop: 'invoice.invoiceStatus',
              },
              {
                name: 'Wystawione dokumenty',
                prop: 'documents',
              },
            ]}
          />
          <Table
            data={fleet.hiredItems}
            mainData={mainData}
            expandData={expandData}
            colWidth="50%"
            setBreakOpen={setBreakOpen}
            setBreakId={setBreakId}
            setCar={setCar}
          />

          <DialogSlide />
        </>
      )}
    </>
  )
}
