import React from 'react'

import ShopContainer from './../../../containers/ShopContainer'
import CartContainer from './../../../containers/CartContainer'
import CartContainerStep2 from './../../../containers/CartContainerStep2'
import SuccessContainer from './../../../containers/SuccessContainer'
import { List } from './List'

import DateAndPlaceStep1 from './DateAndPlaceStep1'
import DateAndPlaceStep2 from './DateAndPlaceStep2'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {},

  stepper: {
    padding: theme.spacing(3, 0, 3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  cart: {
    position: 'relative',
    right: '6px',
  },
}))

export default function HomeComponent(props) {
  const steps = [
    'miejsce i czas',
    'pojazdy',
    'usługi dodatkowe',
    'dane do umowy',
  ]

  function getStepContent(step, wrapperActiveStep) {
    const isLastStep = activeStep === steps.length - 1
    switch (step) {
      case 0:
        return (
          <div>
            <DateAndPlaceStep1
              {...formValues}
              activeStep={step}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleNext={handleNext}
            />
            <List link="/leases" />
          </div>
        )
      case 1:
        return (
          <div>
            <DateAndPlaceStep2
              {...formValues}
              activeStep={step}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleNext={handleNext}
            />

            <Grid container>
              <Grid item xs={11}>
                <ShopContainer formValues={formValues} />
              </Grid>
              <Grid item xs={1} className={classes.cart}>
                <CartContainer
                  activeStep={step}
                  setActiveStep={wrapperActiveStep}
                />
              </Grid>
            </Grid>
          </div>
        )
      case 2:
        return (
          <div>
            <DateAndPlaceStep2
              {...formValues}
              activeStep={step}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleNext={handleNext}
            />
            <CartContainerStep2
              formValues={formValues}
              activeStep={step}
              setActiveStep={wrapperActiveStep}
              cartDetails={true}
            />
          </div>
        )
      case 3:
        return (
          <div>
            <DateAndPlaceStep2
              {...formValues}
              activeStep={step}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleNext={handleNext}
            />
            <CartContainerStep2
              activeStep={step}
              setActiveStep={wrapperActiveStep}
              isLastStep={isLastStep}
            />
            <SuccessContainer />
          </div>
        )

      default:
        throw new Error('Unknown step')
    }
  }
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)

  const wrapperActiveStep = React.useCallback(
    (val) => {
      setActiveStep(val)
    },
    [setActiveStep]
  )
  const { sourceRentalPlace, startDate, endDate } = props
  const [formValues, setFormValues] = React.useState({
    sourceRentalPlace,
    startDate,
    endDate,
  })

  const handleNext = (newValues) => {
    setActiveStep(activeStep + 1)

    setFormValues({ ...formValues, ...newValues })
  }

  const handleBack = (newValues) => {
    setActiveStep(activeStep - 1)
    setFormValues({ ...formValues, ...newValues })
  }

  return (
    <main className={classes.layout}>
      <Typography component="h1" variant="h5" align="center">
        Nowy najem
      </Typography>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <React.Fragment>
        {getStepContent(activeStep, wrapperActiveStep)}
      </React.Fragment>
    </main>
  )
}
