import React from 'react'
import { get } from '@ui/shared'
import LeaseFieldFactory from './factory/LeaseFieldFactory'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    columnGap: '25px',
    rowGap: '5px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-45px',
  },
  form: {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '30px',
  },
})
const leaseForm = (
  classes,
  lease,
  updatableLease,
  setUpdatableLease,
  header,
  violations
) => {
  return (
    <div className={classes.gridParent}>
      {header.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <LeaseFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={get(lease, y.prop, '')}
            violations={violations}
            updatableParentEntity={updatableLease}
            setUpdatableParentEntity={setUpdatableLease}
            leaseId={lease.id}
            lease={lease}
          />
        </div>
      ))}
    </div>
  )
}

export default function Lease({ lease, header }) {
  const [updatableLease, setUpdatableLease] = React.useState([])
  // eslint-disable-next-line
  const [violations, setViolations] = React.useState([])
  const classes = useStyles()

  return (
    <div className={classes.form}>
      {leaseForm(
        classes,
        lease,
        updatableLease,
        setUpdatableLease,
        header,
        violations
      )}
    </div>
  )
}
