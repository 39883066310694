import { requestConstants } from './../_constants';
var start = function (url) { return ({
    type: requestConstants.START,
    payload: { url: url },
}); };
var success = function () { return ({
    type: requestConstants.SUCCESS,
}); };
var failure = function (error) { return ({
    type: requestConstants.FAILURE,
    payload: { error: error },
}); };
export var requestActions = {
    start: start,
    success: success,
    failure: failure,
};
