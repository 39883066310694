export var ADD_TO_CART = 'ADD_TO_CART';
export var UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export var UPDATE_DATE = 'UPDATE_DATE';
export var CHECKOUT = 'CHECKOUT';
export var REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export var RESET_SHOP = 'RESET_SHOP';
export var addToCart = function (item) {
    return {
        type: ADD_TO_CART,
        item: item,
    };
};
export var updateQuantity = function (cartItem, index, quantity) {
    return {
        type: UPDATE_QUANTITY,
        cartItem: cartItem,
        index: index,
        quantity: quantity,
    };
};
export var updateDate = function (cartItem, index, startDate, endDate, daysOfRent) {
    return {
        type: UPDATE_DATE,
        cartItem: cartItem,
        index: index,
        startDate: startDate,
        endDate: endDate,
        daysOfRent: daysOfRent,
    };
};
export var checkout = function () {
    return {
        type: CHECKOUT,
    };
};
export var removeCartItem = function (index) {
    return {
        type: REMOVE_CART_ITEM,
        index: index,
    };
};
export var resetShop = function () {
    return {
        type: RESET_SHOP,
    };
};
