import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {},
  appBarSpacer: theme.mixins.toolbar,
  content: {},
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(7),
  },
  paper: {
    width: 'auto',

    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  marB: {
    marginBottom: '20px',
  },
}))
