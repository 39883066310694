import React from 'react'
import { default as SwitchMode } from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { useTheme } from '@ui/shared'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  formControl: {
    padding: '10px',
  },
})
export const General = () => {
  const classes = useStyles()
  const themeState = useTheme()

  return (
    <FormGroup className={classes.formControl}>
      <FormControlLabel
        control={
          <SwitchMode
            checked={themeState.dark}
            onChange={() => themeState.toggle()}
            aria-label="login switch"
          />
        }
        label={
          themeState.dark ? 'Przełącz na tryb jasny' : 'Przełącz na tryb ciemny'
        }
      />
    </FormGroup>
  )
}
