import React from 'react'
import { useSelector } from 'react-redux'
import { FormFactory } from '@ui/shared'
import * as schema from '@ui/shared'
import { getDefinitionProperties } from '@ui/shared'

export const DetailsForm = ({ resource }) => {
  const definitions = useSelector((state) => state.schema?.definitions)
  const properties = getDefinitionProperties(
    definitions[schema?.customer?.definition],
    schema?.customer?.properties
  )
  const customer = useSelector((state) => state.user?.profile?.personalDetails)

  return (
    <FormFactory
      title="Dane klienta"
      url={schema.customer.set}
      method="PUT"
      properties={properties}
      resource={customer !== null ? customer : resource}
      details={resource}
    />
  )
}
