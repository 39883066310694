import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CardMedia from '@material-ui/core/CardMedia'
import { polishPlural } from '@ui/shared'

const styles = (theme) => ({
  title: {
    width: 200,
    height: 200,
  },
  textField: {
    marginLeft: 20,
    marginRight: 15,
    marginBottom: 20,
  },
  chip: {
    marginRight: 20,
  },
  itemName: {
    marginRight: 15,
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundSize: 'contain',
  },
})

function CartItemComponent(props) {
  const initialState = {
    quantity: props.cartItem.quantity,
  }
  // eslint-disable-next-line
  const [state, setState] = React.useState(initialState)
  // eslint-disable-next-line
  const [checked, setChecked] = React.useState()

  const handleSwitch = (value, index) => {
    let newData
    setChecked(() => {
      newData = [...cartItem.options]
      newData[index].checked = value

      return { newData }
    })
    updateQuantity(newData, removeCartItemIndex, state.quantity)
  }
  const handleSwitch2 = (value, index) => {
    let newData2
    setChecked(() => {
      newData2 = [...cartItem.options2]
      newData2[index].checked = value

      return { newData2 }
    })
    updateQuantity(newData2, removeCartItemIndex, state.quantity)
  }
  const {
    classes,
    cartItem,
    updateQuantity,
    removeCartItem,
    removeCartItemIndex,
    cartDetails,
    isLastStep,
  } = props

  const daysOfRent = useSelector((state) => state.shop.daysOfRent)
  const discount = useSelector(
    (state) => state.user.profile.carCustomer.discountLevel.discountRate
  )
  const totalPrice = useSelector((state) => state.shop.totalPrice)

  return (
    <div>
      <ListItem key={cartItem.id} style={cartDetails && { display: 'block' }}>
        <Chip
          color="primary"
          className={classes.chip}
          label={removeCartItemIndex + 1}
        />
        {cartDetails && (
          <div className={classes.card}>
            <CardMedia
              className={classes.media}
              image={`${process.env.REACT_APP_API_ENTRYPOINT}${cartItem.pictureURL}`}
              title={cartItem.name}
            />
          </div>
        )}
        <Typography
          variant={cartDetails ? ' headline' : 'body2'}
          component={cartDetails && 'h2'}
          className={classes.itemName}
          gutterBottom
        >
          {cartItem.name}
        </Typography>
        <Typography
          variant={cartDetails ? 'headline' : 'body2'}
          component={cartDetails && 'h3'}
          className={classes.itemPrice}
          gutterBottom
        >
          {cartDetails ? (
            <div>
              <p>
                {((cartItem.unitPrice * daysOfRent) / 100).toLocaleString(
                  'pl-PL',
                  {
                    style: 'currency',
                    currency: 'PLN',
                  }
                )}{' '}
                Wynajem auta za {daysOfRent}
                {polishPlural(' dzień ', ' dni ', ' dni ', daysOfRent)}
              </p>
              <p>
                {(cartItem.bail / 100).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN',
                })}{' '}
                Kaucja zwrotna
              </p>
              {discount !== 0 && (
                <p>
                  -
                  {((totalPrice * (discount / 100)) / 100).toLocaleString(
                    'pl-PL',
                    {
                      style: 'currency',
                      currency: 'PLN',
                    }
                  )}{' '}
                  Zniżka
                </p>
              )}
            </div>
          ) : (
            <div>
              {(cartItem.price / 100).toLocaleString('pl-PL', {
                style: 'currency',
                currency: 'PLN',
              })}
            </div>
          )}
        </Typography>

        {cartDetails && (
          <FormControl className={classes.formControl} component="fieldset">
            {cartItem.options.length !== 0 && (
              <FormLabel component="legend">Dodatki</FormLabel>
            )}
            <FormGroup>
              {cartItem.options.map((option, index) => {
                const price = (option.price / 100).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN',
                })
                return (
                  <FormControlLabel
                    key={`option-${index}`}
                    control={
                      <Checkbox
                        checked={option.checked}
                        onChange={(e) => handleSwitch(e.target.checked, index)}
                      />
                    }
                    label={`${price} - ${option.name}`}
                  />
                )
              })}
            </FormGroup>
            {cartItem.options2.length !== 0 && (
              <FormLabel component="legend">Usługi</FormLabel>
            )}
            <FormGroup>
              {cartItem.options2.map((option, index) => {
                const price = (option.price / 100).toLocaleString('pl-PL', {
                  style: 'currency',
                  currency: 'PLN',
                })
                return (
                  <FormControlLabel
                    key={`option-${index}`}
                    control={
                      <Checkbox
                        checked={option.checked}
                        onChange={(e) => handleSwitch2(e.target.checked, index)}
                      />
                    }
                    label={`${price} - ${option.name}`}
                  />
                )
              })}
            </FormGroup>
          </FormControl>
        )}
        {!isLastStep && (
          <>
            <Tooltip title="Usuń" placement="top">
              <IconButton
                color="secondary"
                className={classes.icon}
                onClick={() => removeCartItem(removeCartItemIndex)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </ListItem>
      <hr />
    </div>
  )
}

CartItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  cartItem: PropTypes.object.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  removeCartItemIndex: PropTypes.number.isRequired,
}

export default withStyles(styles)(CartItemComponent)
