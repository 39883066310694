var required = function (value) {
    var result = !!value;
    return { result: result, message: !result && 'Pole wymagane' };
};
var email = function (value) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var result = re.test(String(value).toLowerCase());
    return { result: result, message: !result && 'Niepoprawny adres e-mail' };
};
var password = function (value) {
    var result = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value);
    return {
        result: result,
        message: !result &&
            'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    };
};
export var validators = {
    required: required,
    email: email,
    password: password,
};
