var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { authConstants } from './../_constants';
import { tokenExpiration } from './../_helpers/tokenExpiration';
export var authReducer = function (state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                isLogging: true,
                data: { email: action.payload.email },
            };
        case authConstants.LOGIN_SUCCESS:
        case authConstants.REFRESH_SUCCESS:
        case authConstants.LOGIN_RESTORE:
            return {
                isLogged: true,
                data: {
                    token: action.payload.token,
                    exp: tokenExpiration(action.payload.token),
                },
            };
        case authConstants.REFRESH_REQUEST:
            return __assign({ isRefreshing: true }, state);
        case authConstants.REFRESH_FAILURE:
        case authConstants.LOGIN_FAILURE:
        case authConstants.LOGOUT:
            return {};
        default:
            return state;
    }
};
