var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
export var DatePickerField = function (_a) {
    var fieldName = _a.fieldName, type = _a.type, value = _a.value, _b = _a.violations, violations = _b === void 0 ? [] : _b, updatableParentEntity = _a.updatableParentEntity, setUpdatableParentEntity = _a.setUpdatableParentEntity, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c;
    var _d = React.useState(''), currentValue = _d[0], setCurrentValue = _d[1];
    React.useEffect(function () {
        value && setCurrentValue(value);
    }, [value]);
    var fieldViolations = violations.filter(function (violation) { return violation.propertyPath === type; });
    var handleDateChange = function (date) {
        var _a;
        setCurrentValue(date);
        setUpdatableParentEntity(__assign(__assign({}, updatableParentEntity), (_a = {}, _a[type] = date, _a)));
    };
    return (React.createElement(React.Fragment, null,
        fieldName && (React.createElement("div", null,
            React.createElement("b", null,
                fieldName,
                ":"))),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils },
                    React.createElement(KeyboardDatePicker, { format: "DD.MM.YYYY", value: currentValue ? new Date(currentValue) : null, onChange: handleDateChange, readOnly: readOnly })),
                fieldViolations.length
                    ? fieldViolations.map(function (violation, i) { return (React.createElement(FormHelperText, { key: i, error: true }, violation.message)); })
                    : ''))));
};
