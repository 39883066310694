import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,

    fontSize: '1.1rem',
  },
}))
export const Title = (props) => {
  const classes = useStyles()

  return (
    <Typography
      className={classes.title}
      variant="h6"
      noWrap
      component={props.link && Link}
      to="/"
      style={props.link && { textDecoration: 'none', color: 'unset' }}
    >
      {props.children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.node,
}
