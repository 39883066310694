var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { authHeader } from './../_helpers/authHeader';
var login = function (email, password) { return fetchToken(email, password); };
var refresh = function () { return fetchToken(); };
var fetchToken = function (email, password) {
    var requestOptions = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(email && password ? { email: email, password: password } : {}),
    };
    if (!email && !password) {
        requestOptions.headers = __assign(__assign({}, requestOptions.headers), authHeader());
    }
    var url = process.env.REACT_APP_API_ENTRYPOINT + "/" + (email && password ? 'security/login' : 'users/refresh');
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(function (response) {
        var token = response.token;
        return token;
    });
};
var handleResponse = function (response) {
    return response.text().then(function (text) {
        var data = text && JSON.parse(text);
        if (!response.ok) {
            var error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
};
export var authService = {
    login: login,
    refresh: refresh,
};
