var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { validate } from './../../../_helpers/validate';
export var PasswordRepeatType = function (_a) {
    var name = _a.name, label = _a.label, hint = _a.hint, disabled = _a.disabled, validators = _a.validate, setValue = _a.setValue, setValidator = _a.setValidator, setErrorMessageSetter = _a.setErrorMessageSetter;
    var _b = useState({
        password: {
            value: '',
            error: { status: false, message: false },
        },
        repeat: {
            value: '',
            error: { status: false, message: false },
        },
    }), state = _b[0], setState = _b[1];
    var handleChange = {
        password: function (e) {
            var value = e.target.value;
            setState(function (state) { return (__assign(__assign({}, state), { password: __assign(__assign({}, state.password), { value: value }) })); });
            validator.complete(value, state.repeat.value);
        },
        repeat: function (e) {
            var value = e.target.value;
            setState(function (state) { return (__assign(__assign({}, state), { repeat: __assign(__assign({}, state.repeat), { value: value }) })); });
            validator.repeat(state.password.value, value);
        },
    };
    var validator = {
        password: function (value) {
            var valid = validate(validators, value);
            setState(function (state) { return (__assign(__assign({}, state), { password: __assign(__assign({}, state.password), { error: { status: !valid.result, message: valid.message } }) })); });
            setValue(name, !valid.result ? new Error(valid.message) : value);
            return valid.result;
        },
        repeat: function (password, repeat) {
            var valid = {
                result: password === repeat,
                message: password === repeat || 'Hasła nie zgadzają się',
            };
            setState(function (state) { return (__assign(__assign({}, state), { repeat: __assign(__assign({}, state.repeat), { error: { status: !valid.result, message: valid.message } }) })); });
            setValue(name + 'Repeat', !valid.result ? new Error(valid.message) : repeat);
            return valid.result;
        },
        complete: function (password, repeat) {
            var isPasswordValid = validator.password(password);
            var isRepeatValid = validator.repeat(password, repeat);
            return isPasswordValid && isRepeatValid;
        },
    };
    var setErrorMessage = function (message) {
        setState(function (state) { return (__assign(__assign({}, state), { password: __assign(__assign({}, state.password), { error: { status: true, message: message } }) })); });
    };
    useEffect(function () {
        setValue(name, state.password.value);
        setValue(name + 'Repeat', state.repeat.value);
        setValidator(name, validator.complete, [name, name + 'Repeat']);
        setErrorMessageSetter(name, setErrorMessage);
        // eslint-disable-next-line
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { name: name, type: "password", label: label + ' *', value: state.password.value, disabled: disabled, onChange: handleChange.password, error: state.password.error.status, helperText: state.password.error.status
                ? state.password.error.message
                : hint.origin }),
        React.createElement("div", { style: {
                marginBottom: '12px',
            } },
            React.createElement(TextField, { name: name + 'Repeat', type: "password", label: label + ' repeat *', value: state.repeat.value, disabled: disabled, onChange: handleChange.repeat, error: state.repeat.error.status, helperText: state.repeat.error.status ? state.repeat.error.message : hint.repeat }))));
};
