var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@material-ui/styles';
import { theme } from 'theme';
var defaultContextData = {
    dark: false,
    toggle: function () { },
};
var ThemeContext = React.createContext(defaultContextData);
var useTheme = function () { return React.useContext(ThemeContext); };
var useEffectDarkMode = function () {
    var _a = React.useState({
        dark: false,
        hasThemeMounted: false,
    }), themeState = _a[0], setThemeState = _a[1];
    React.useEffect(function () {
        var lsDark = localStorage.getItem('dark') === 'true';
        setThemeState(__assign(__assign({}, themeState), { dark: lsDark, hasThemeMounted: true }));
        // eslint-disable-next-line
    }, []);
    return [themeState, setThemeState];
};
var ThemeProvider = function (_a) {
    var children = _a.children;
    var _b = useEffectDarkMode(), themeState = _b[0], setThemeState = _b[1];
    if (!themeState.hasThemeMounted) {
        return React.createElement("div", null);
    }
    var toggle = function () {
        var dark = !themeState.dark;
        localStorage.setItem('dark', JSON.stringify(dark));
        setThemeState(__assign(__assign({}, themeState), { dark: dark }));
    };
    var computedTheme = themeState.dark ? theme('dark') : theme('light');
    return (React.createElement(EmotionThemeProvider, { theme: computedTheme },
        React.createElement(ThemeContext.Provider, { value: {
                dark: themeState.dark,
                toggle: toggle,
            } }, children)));
};
export { ThemeProvider, useTheme };
