var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var prefixPaths = function (prefix, items, paths) {
    if (paths === void 0) { paths = ['path']; }
    return items.map(function (item) { return (__assign(__assign({}, item), paths.reduce(function (obj, path) {
        var _a;
        return Object.assign(obj, item[path] && (_a = {},
            _a[path] = ("" + prefix + item[path]).replace(/\/$/, ''),
            _a));
    }, {}))); });
};
