import React from 'react'
import { prefixPaths } from '@ui/shared'
import { generateRoutes } from '@ui/shared'
import { AccountCircle, Settings as SettingsIcon } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { General } from './General'

import { ProfileContainer } from './Profile'

const routes = () => [
  {
    title: 'Ustawienia',
    type: Route,
    component: General,
    path: '/general',
    exact: true,
    icon: SettingsIcon,
  },
  {
    title: 'Profil',
    type: Route,
    component: ProfileContainer,
    path: '/profile',
    exact: true,
    icon: AccountCircle,
  },
  {
    title: 'Ustawienia',
    type: Redirect,
    from: '/',
    to: '/general',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Settings = (props) => {
  const { match } = props
  return (
    <Page
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
    />
  )
}
