import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  toolbar: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  light: {
    color: 'white',
  },
  logo: {
    marginRight: '10px',
  },
}))
