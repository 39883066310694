var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
export var NumberField = function (_a) {
    var fieldName = _a.fieldName, type = _a.type, value = _a.value, _b = _a.violations, violations = _b === void 0 ? [] : _b, updatableParentEntity = _a.updatableParentEntity, setUpdatableParentEntity = _a.setUpdatableParentEntity, _c = _a.renderValue, renderValue = _c === void 0 ? function (value) { return +value; } : _c, _d = _a.uploadValue, uploadValue = _d === void 0 ? function (value) { return +value; } : _d, inputProps = _a.inputProps, _e = _a.readOnly, readOnly = _e === void 0 ? false : _e;
    var _f = React.useState(false), open = _f[0], setOpen = _f[1];
    var _g = React.useState(value || ''), currentValue = _g[0], setCurrentValue = _g[1];
    var fieldViolations = violations.filter(function (violation) { return violation.propertyPath === type; });
    var handleOpen = function () {
        setOpen(!readOnly && true);
    };
    var handleClose = function () { return setOpen(false); };
    var handleChange = function (event) {
        var _a;
        var newValue = event.target.value;
        setCurrentValue(newValue);
        setUpdatableParentEntity(__assign(__assign({}, updatableParentEntity), (_a = {}, _a[type] = uploadValue(newValue), _a)));
    };
    return (React.createElement(React.Fragment, null,
        fieldName && (React.createElement("div", null,
            React.createElement("b", null,
                fieldName,
                ":"))),
        React.createElement("div", null,
            React.createElement("div", { onClick: handleOpen },
                renderValue(currentValue) ||
                    (!readOnly && React.createElement(CreateIcon, { fontSize: "small" })),
                fieldViolations.length
                    ? fieldViolations.map(function (violation, i) { return (React.createElement(FormHelperText, { key: i, error: true }, violation.message)); })
                    : ''),
            React.createElement(Dialog, { open: open, onClose: handleClose },
                React.createElement(DialogTitle, null,
                    React.createElement("span", null, fieldName)),
                React.createElement(DialogContent, null,
                    React.createElement(TextField, { defaultValue: currentValue, onChange: handleChange, type: "number", inputProps: inputProps })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Akceptuj"))))));
};
