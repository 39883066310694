import React from 'react'
import Button from '@material-ui/core/Button'
import { usePersistentCanvas } from './CanvasHooks'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  canvasParent: {
    position: 'relative',
    width: '679px',
    height: '446px',
  },
  canvasImg: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  canvas: {
    position: 'relative',
  },
  btn: {
    marginTop: '10px',
    marginRight: '10px',
  },
})
function CanvasContainer(props) {
  const classes = useStyles()
  const {
    type,
    damagePlaces,
    updatableParentEntity,
    setUpdatableParentEntity,
  } = props
  const [locations, setLocations, canvasRef] = usePersistentCanvas(damagePlaces)

  function handleCanvasClick(e) {
    const rect = e.target.getBoundingClientRect()
    setLocations([
      ...locations,
      { x: parseInt(e.clientX - rect.left), y: parseInt(e.clientY - rect.top) },
    ])
  }

  React.useEffect(() => {
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: locations.map((el) => ({
        createdAt: el.createdAt,
        x: el.x,
        y: el.y,
      })),
    })
    // eslint-disable-next-line
  }, [locations])

  function handleClear() {
    setLocations([])
  }

  function handleUndo() {
    setLocations(locations.slice(0, -1))
  }

  return (
    <>
      <div className="controls">
        <Button
          onClick={handleClear}
          color="primary"
          variant="contained"
          className={classes.btn}
        >
          Wyczyść
        </Button>
        <Button
          onClick={handleUndo}
          color="primary"
          variant="outlined"
          className={classes.btn}
        >
          Cofnij
        </Button>
      </div>
      <div style={{ overflowX: 'scroll' }}>
        <div className={classes.canvasParent}>
          <img
            className={classes.canvasImg}
            src="/images/canvas-background.png"
            alt="background"
          />
          <canvas
            ref={canvasRef}
            width={679}
            height={446}
            className={classes.canvas}
            onClick={handleCanvasClick}
          />
        </div>
      </div>
    </>
  )
}

export default CanvasContainer
