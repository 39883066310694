import { connect } from 'react-redux'
import { addToCart } from '@ui/shared'
import ShopComponent from './../pages/Home/components/ShopComponent'

const mapStateToProps = (state, ownProps) => {
  return {
    inventory: state.shop.inventory,
    formValues: ownProps.formValues,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
  }
}

const ShopContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopComponent)

export default ShopContainer
