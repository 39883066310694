var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { validate } from './../../../_helpers/validate';
export var StringType = function (_a) {
    var name = _a.name, type = _a.type, label = _a.label, hint = _a.hint, value = _a.value, disabled = _a.disabled, validators = _a.validate, setValue = _a.setValue, setValidator = _a.setValidator, setErrorMessageSetter = _a.setErrorMessageSetter;
    var _b = useState({
        value: value || '',
        error: { status: false, message: false },
    }), state = _b[0], setState = _b[1];
    var handleChange = function (e) {
        var value = e.target.value;
        setState(function (state) { return (__assign(__assign({}, state), { value: value })); });
        validator(value);
    };
    var validator = function (value) {
        var valid = validate(validators, value);
        setState(function (state) { return (__assign(__assign({}, state), { error: { status: !valid.result, message: valid.message } })); });
        setValue(name, !valid.result ? new Error(valid.message) : value);
        return valid.result;
    };
    var setErrorMessage = function (message) {
        setState(function (state) { return (__assign(__assign({}, state), { error: { status: true, message: message } })); });
    };
    useEffect(function () {
        setValue(name, value);
        setValidator(name, validator);
        setErrorMessageSetter(name, setErrorMessage);
        // eslint-disable-next-line
    }, []);
    return (React.createElement(TextField, { name: name, type: type === 'string' ? 'text' : type, label: label + (validators.includes('required') ? ' *' : ''), value: state.value, disabled: disabled, onChange: handleChange, error: state.error.status, helperText: state.error.status ? state.error.message : hint }));
};
