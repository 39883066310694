import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppBarContent } from '@ui/shared'
import { authActions } from '@ui/shared'
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Badge,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Notifications, Phone } from '@material-ui/icons'
import { useStyles } from './AppBarStyles'

import { Title } from 'components/Title'

export const HomeAppBar = () => {
  const classes = useStyles()
  const alerts = useSelector(
    (state) => state.user.profile?.carCustomer?.canAlerts
  )
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  return (
    <MuiAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img className={classes.logo} src="/images/logo.png" alt="logo" />
        <Title link={true}>PANEL KLIENTA</Title>
        <IconButton color="inherit" href="tel:+4822123456789">
          <Phone />
        </IconButton>
        <div>
          <IconButton
            color="inherit"
            aria-label="can alerts"
            aria-controls="can-alerts"
            aria-haspopup="true"
            onClick={alerts?.[0]?.length !== 0 && handleMenu}
          >
            <Badge badgeContent={alerts?.length} color="secondary">
              <Notifications />
            </Badge>
          </IconButton>
          <Menu
            id="can-alerts"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={!!anchorEl}
            onClose={handleClose}
          >
            {alerts
              ?.slice(1)
              .slice(-5)
              .map((item, i) => {
                return (
                  item.length !== 0 && (
                    <MenuItem onClick={handleClose} key={`ca-${i}`}>
                      {item.message}
                    </MenuItem>
                  )
                )
              })}
          </Menu>
        </div>

        <AppBarContent handlers={{ handleLogout }} />
      </Toolbar>
    </MuiAppBar>
  )
}
