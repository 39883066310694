import { AuthError } from './authError';
export var handleResponse = function (response) {
    if (response.status === 401 || response.status === 403) {
        return Promise.reject(new AuthError({
            response: {
                title: response.status,
                detail: response.statusText,
                violations: [],
            },
        }));
    }
    return response.text().then(function (text) {
        var data = text && JSON.parse(text);
        if (!response.ok) {
            return Promise.reject({
                response: {
                    title: !(data instanceof Array) ? data.title : 'Error',
                    detail: !(data instanceof Array) ? data.detail : response.statusText,
                    violations: !(data instanceof Array) ? data.violations || [] : data,
                },
            });
        }
        return data;
    });
};
