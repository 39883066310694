// eslint-disable-next-line
const api = {
  pdfs: '/pdfs',
  emails: '/emails/send',
}

const carApi = {
  ...api,
  appType: 'cars',
  home: 'car',
  customers: '/car_customers',
  hires: '/car_hires',
  hireItems: '/car_hire_items',
  invoices: '/car_invoices',
  reports: '/cars',
  documentTemplate: '/car_document_templates',
  pdfsInvoicesFull: '/pdfs/carInvoicesFullList',
  pdfsInvoicesShort: '/pdfs/carInvoicesShortenList',
  invoicingNumberings: '/car_invoicing_numberings',
  companyConfig: '/company_configs/car',
  hireDocument: '/pdfs/carHireDocument',
  hireItemDocument: '/pdfs/carHireItemDocument',
  returns: '/car_returns',
  repairs: '/car_repairs',
  iri: {
    invoices: '/car_invoices/',
    seller: '/users/',
    customer: '/car_customers/',
    invoicingNumbering: '/car_invoicing_numberings/',
    hires: '/car_hires/',
    hireItems: '/car_hire_items/',
    documentTemplate: '/car_document_templates/',
    equipment: '/cars/',
  },
}

export default carApi
