import { tokenExpiration } from './tokenExpiration';
export var tokenVerify = function (token) {
    if (!token) {
        return null;
    }
    var now = new Date();
    var exp = tokenExpiration(token);
    if (now > exp) {
        return false;
    }
    return true;
};
