var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestActions } from './../../_actions';
import { fetchDataHandleAuthError } from './../../_helpers/fetchDataHandleAuthError';
import { notification } from './../../_helpers/notification';
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { StringType } from './fields/StringType';
import { PasswordRepeatType } from './fields/PasswordRepeatType';
import { useStyles } from './FormFactoryStyles';
import { Loader } from './../Loader';
import { Title } from './../Title';
import { requestConstants } from './../../_constants';
var fieldTypes = {
    string: StringType,
    password: StringType,
    passwordRepeat: PasswordRepeatType,
};
export var FormFactory = function (_a) {
    var _b = _a.title, title = _b === void 0 ? null : _b, url = _a.url, _c = _a.method, method = _c === void 0 ? 'PUT' : _c, properties = _a.properties, _d = _a.resource, resource = _d === void 0 ? null : _d, _e = _a.handleSuccess, handleSuccess = _e === void 0 ? null : _e, details = _a.details;
    var classes = useStyles();
    var _f = useState({
        values: {},
        errors: {},
        validators: {},
        errorMessageSetters: {},
        invalid: 0,
        isProcessing: false,
        url: url.includes(':id') && resource
            ? url.replace(':id', details ? details.id : resource.id)
            : url,
    }), state = _f[0], setState = _f[1];
    var dispatch = useDispatch();
    var setValue = function (name, value) {
        var isInvalid = value instanceof Error;
        setState(function (state) {
            var _a, _b;
            return (__assign(__assign({}, state), { values: __assign(__assign({}, state.values), (_a = {}, _a[name] = !isInvalid && value, _a)), errors: __assign(__assign({}, state.errors), (_b = {}, _b[name] = isInvalid, _b)), invalid: state.invalid +
                    (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2) }));
        });
    };
    var setValidator = function (name, validator, values) {
        setState(function (state) {
            var _a;
            return (__assign(__assign({}, state), { validators: __assign(__assign({}, state.validators), (_a = {}, _a[name] = { validator: validator, values: values || [name] }, _a)) }));
        });
    };
    var setErrorMessageSetter = function (name, errorMessageSetter) {
        setState(function (state) {
            var _a;
            return (__assign(__assign({}, state), { errorMessageSetters: __assign(__assign({}, state.errorMessageSetters), (_a = {}, _a[name] = errorMessageSetter, _a)) }));
        });
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        if (Object.keys(state.validators).length &&
            !Object.keys(state.validators).reduce(function (status, name) {
                var _a;
                return (_a = state.validators[name]).validator.apply(_a, Object.keys(state.values)
                    .filter(function (item) { return state.validators[name].values.includes(item); })
                    .map(function (item) { return state.values[item]; })) && status;
            }, true)) {
            return;
        }
        setState(function (state) { return (__assign(__assign({}, state), { isProcessing: true })); });
        dispatch(requestActions.start(state.url));
        fetchDataHandleAuthError(state.url, method, { body: JSON.stringify(state.values) }, function () {
            setState(function (state) { return (__assign(__assign({}, state), { isProcessing: false })); });
            dispatch(requestActions.success());
            notification('success', method === 'PUT' ? 'Rekord zaktualizowany' : 'Rekord dodany', 'Zapisano');
            handleSuccess && handleSuccess(state.values);
        }, function (error) {
            setState(function (state) { return (__assign(__assign({}, state), { isProcessing: false })); });
            error.response.violations.map(function (item) {
                return state.errorMessageSetters[item.propertyPath](item.message);
            });
            notification('error', error.response.violations.length
                ? 'Incorrect form'
                : error.response.detail, error.response.title);
        }, dispatch, requestConstants.FAILURE);
    };
    return (React.createElement(React.Fragment, null,
        title && React.createElement(Title, null, title),
        state.isProcessing && React.createElement(Loader, null),
        React.createElement("form", { onSubmit: handleSubmit, className: classes.root },
            Object.keys(properties).map(function (name) {
                var FieldComponent = fieldTypes[properties[name].type];
                return (React.createElement("div", { key: name, style: {
                        marginBottom: '12px',
                    } },
                    React.createElement(FieldComponent, { name: name, type: properties[name].type, label: properties[name].description, hint: properties[name].hint, value: resource && resource[name], disabled: properties[name].disabled
                            ? properties[name].disabled
                            : state.isProcessing, validate: properties[name].validate || [], setValue: setValue, setValidator: setValidator, setErrorMessageSetter: setErrorMessageSetter })));
            }),
            React.createElement(Button, { type: "submit", variant: "contained", color: "secondary", size: "small", startIcon: React.createElement(Save, null), className: classes.save, disabled: state.isProcessing || !!state.invalid }, "Zapisz"))));
};
