var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
var useStyles = makeStyles(function (theme) { return ({
    centerBox: {
        textAlign: 'center',
        padding: '5px 0px',
    },
}); });
export var StringField = function (_a) {
    var fieldName = _a.fieldName, type = _a.type, value = _a.value, _b = _a.violations, violations = _b === void 0 ? [] : _b, updatableParentEntity = _a.updatableParentEntity, setUpdatableParentEntity = _a.setUpdatableParentEntity, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c, _d = _a.center, center = _d === void 0 ? false : _d;
    var classes = useStyles();
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var _f = React.useState(''), currentValue = _f[0], setCurrentValue = _f[1];
    React.useEffect(function () {
        value && setCurrentValue(value);
    }, [value]);
    var fieldViolations = violations.filter(function (violation) { return violation.propertyPath === type; });
    var handleOpen = function () {
        setOpen(!readOnly);
    };
    var handleClose = function () { return setOpen(false); };
    var handleChange = function (event) {
        var _a;
        var newValue = event.target.value;
        setCurrentValue(newValue);
        setUpdatableParentEntity(__assign(__assign({}, updatableParentEntity), (_a = {}, _a[type] = newValue, _a)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("b", null, fieldName && "" + fieldName)),
        React.createElement("div", null,
            React.createElement("div", { onClick: handleOpen, className: center ? classes.centerBox : '' },
                currentValue || (!readOnly && React.createElement(CreateIcon, { fontSize: "small" })),
                fieldViolations.length
                    ? fieldViolations.map(function (violation, i) { return (React.createElement(FormHelperText, { key: i, error: true }, violation.message)); })
                    : ''),
            React.createElement(Dialog, { open: open, onClose: handleClose, "aria-labelledby": "form-dialog-title" },
                React.createElement(DialogTitle, { id: "form-dialog-title" }, fieldName || ''),
                React.createElement(DialogContent, null,
                    React.createElement(TextField, { defaultValue: currentValue, onChange: handleChange })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleClose, color: "primary" }, "Akceptuj"))))));
};
