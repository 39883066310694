export var processError = function (error) {
    return error.response
        ? error
        : {
            response: {
                title: 'Error',
                detail: error.toString(),
                violations: [],
            },
        };
};
