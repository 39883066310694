import React from 'react';
import { IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { UserMenu } from './../menu/UserMenu';
import PropTypes from 'prop-types';
export var AppBarContent = function (_a) {
    var status = _a.status, handlers = _a.handlers;
    return (React.createElement(React.Fragment, null,
        React.createElement(UserMenu, null),
        React.createElement(IconButton, { color: "inherit", onClick: handlers.handleLogout },
            React.createElement(ExitToApp, null))));
};
AppBarContent.propTypes = {
    handlers: PropTypes.shape({
        handleLogout: PropTypes.func.isRequired,
    }).isRequired,
};
