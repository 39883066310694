import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { CssBaseline, Container, Grid, Paper } from '@material-ui/core'
import { useStyles } from './PageStyles'
import { HomeAppBar } from 'components/bar/HomeAppBar'

export const Page = ({ routes }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <HomeAppBar />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Switch>{routes}</Switch>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
      </div>
    </>
  )
}

Page.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}
