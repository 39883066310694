import React from 'react'
import { prefixPaths } from '@ui/shared'
import { generateRoutes } from '@ui/shared'
import { Route } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { LeaseContainer } from './Lease'

const routes = () => [
  {
    type: Route,
    component: LeaseContainer,
    path: '/:leaseId',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Leases = (props) => {
  const { match } = props
  return (
    <Page
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
    />
  )
}
