import React from 'react'
import { useSelector } from 'react-redux'
import { FormFactory } from '@ui/shared'
import * as schema from '@ui/shared'
import { getDefinitionProperties } from '@ui/shared'

export const ChangePasswordForm = ({ resource }) => {
  const definitions = useSelector((state) => state.schema?.definitions)
  const properties = getDefinitionProperties(
    definitions[schema.changePassword.definition],
    schema.changePassword.properties
  )

  return (
    <FormFactory
      title="Zmiana hasła"
      url={schema.changePassword.set}
      method="PUT"
      properties={properties}
      resource={resource}
    />
  )
}
