import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import RemoveIconCircle from '@material-ui/icons/RemoveCircle'
import Input from '@material-ui/core/Input'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { authHeader } from '@ui/shared'

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& ul': {
      width: '100%',
    },
    marginBottom: '30px',
  },
  item: {
    border: '1px solid black',
    height: '160px',
    width: '100%',
    objectFit: 'cover',
  },
  titleBar: {
    background: 'transparent',
  },
  rightIcon: {
    float: 'right',
    cursor: 'pointer',
  },
  centerIcon: {
    position: 'relative',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },

  fixed: {
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 48px)',
  },
}))

export default function GalleryField({
  fieldName,
  type,
  photos,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  isPhoto,
}) {
  const endPoint = isPhoto ? 'photos' : 'files'
  const iri = (item) => `${endPoint}/${item.id}`
  const [openGallery, setOpenGallery] = React.useState(false)
  const [openUpload, setOpenUpload] = React.useState(false)

  const [currentPhotos, setCurrentPhotos] = React.useState([])

  React.useEffect(() => {
    photos && setCurrentPhotos(photos)
  }, [photos])

  const [uploadViolations, setUploadViolations] = React.useState([])

  const classes = useStyles()

  const fieldViolations = violations.filter(
    (violation) => violation.propertyPath === type
  )

  const handleOpenGallery = () => setOpenGallery(true)
  const handleCloseGallery = () => setOpenGallery(false)
  const handleOpenUpload = () => setOpenUpload(true)
  const handleCloseUpload = () => setOpenUpload(false)
  const handleUpload = () => {
    const file = document.querySelector(isPhoto ? '#photo' : '#file').files[0]

    const formData = new FormData()
    formData.append('file', file)
    const postData = async (slug, data) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/${slug}`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
        body: data,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response.json()
        })
        .then((json) => {
          setNewPhotos([...currentPhotos, json])
          setUploadViolations([])
          setOpenUpload(false)
        })
        .catch((errorPromise) => {})
    }
    postData(endPoint, formData)
  }

  const createHandleDelete = (id) => {
    return () => {
      const newPhotos = currentPhotos.filter((photo) => photo.id !== id)
      setNewPhotos(newPhotos)
    }
  }

  const setNewPhotos = (newPhotos) => {
    setCurrentPhotos(newPhotos)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newPhotos.map(iri),
    })
  }

  return (
    <React.Fragment>
      <Button onClick={handleOpenGallery} color="primary">
        {fieldName}
      </Button>
      {currentPhotos?.length} {isPhoto ? 'zdjęć' : 'dokumentów'}
      {fieldViolations.length
        ? fieldViolations.map((violation, i) => (
            <FormHelperText key={i} error={true}>
              {violation.message}
            </FormHelperText>
          ))
        : ''}
      <Dialog
        open={openGallery}
        onClose={handleCloseGallery}
        fullScreen={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {fieldName}

          <CloseIcon
            onClick={handleCloseGallery}
            className={classes.rightIcon}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.list}>
            <GridList cols={1} cellHeight={160} spacing={10}>
              {currentPhotos?.map((photo) => (
                <GridListTile key={photo.id}>
                  <div>
                    {isPhoto ? (
                      <img
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}${photo?.url}`}
                        alt=""
                        className={classes.item}
                      />
                    ) : (
                      <ListItem button>
                        <ListItemIcon>
                          <FileCopyIcon />
                        </ListItemIcon>
                        <ListItemText primary={photo?.url} />
                      </ListItem>
                    )}
                  </div>
                  <GridListTileBar
                    titlePosition="top"
                    actionPosition="left"
                    className={classes.titleBar}
                    actionIcon={
                      <RemoveIconCircle
                        onClick={createHandleDelete(photo.id)}
                      />
                    }
                  ></GridListTileBar>
                </GridListTile>
              ))}
            </GridList>
          </div>
          <Button
            onClick={handleOpenUpload}
            className={classes.fixed}
            variant="contained"
            color="primary"
          >
            Wgraj
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUpload}
        onClose={handleCloseUpload}
        maxWidth="md"
        fullWidth
        aria-labelledby="upload-dialog"
      >
        <DialogTitle id="upload-dialog">Dodaj zdjęcie</DialogTitle>
        <DialogContent>
          <Input type="file" name="file" id={isPhoto ? 'photo' : 'file'} />
          {uploadViolations.length ? (
            <FormHelperText error={true}>
              {uploadViolations[0].message}
            </FormHelperText>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload} color="primary">
            Prześlij
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
