import React, { useState } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
export var UserMenu = function () {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var profile = useSelector(function (state) { return state.user.profile; });
    var items = [
        { path: '/settings/profile', title: profile && profile.firstName },
        { path: '/settings/general', title: 'Ustawienia' },
    ];
    var handleMenu = function (e) {
        setAnchorEl(e.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { "aria-label": "user profile", "aria-controls": "user-menu", "aria-haspopup": "true", onClick: handleMenu, color: "inherit" },
            React.createElement(AccountCircle, null)),
        React.createElement(Menu, { id: "user-menu", anchorEl: anchorEl, anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, keepMounted: true, transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, open: !!anchorEl, onClose: handleClose },
            items.map(function (item, i) {
                return (React.createElement(MenuItem, { onClick: handleClose, key: "um-" + i, component: Link, to: item.path }, item.title));
            }),
            React.createElement(MenuItem, { onClick: handleClose }, "Zamknij"))));
};
