import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { schemaReducer } from './schema.reducer';
import { userReducer } from './user.reducer';
import { shopReducer } from './shop.reducer';
import { leaseReducer } from './lease.reducer';
var reducers = combineReducers({
    auth: authReducer,
    schema: schemaReducer,
    user: userReducer,
    shop: shopReducer,
    lease: leaseReducer,
});
export default reducers;
