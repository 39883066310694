import React from 'react'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import CartComponentContent from './CartComponentContent'

import Badge from '@material-ui/core/Badge'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import IconButton from '@material-ui/core/IconButton'

import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  totalItems: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  light: {
    color: 'white',
  },
  totalTitle: {
    marginTop: 5,
    marginRight: 10,
  },
  badge: {
    top: 1,
    right: -15,
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`,
  },
  fixed: {
    position: 'sticky',
    top: '0',
    right: '0',
  },
}))

export default function CartComponent(props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const { cart } = props
  return (
    <div className={classes.fixed}>
      <Tooltip
        onClick={toggleDrawer('right', true)}
        title="Total Items"
        placement="top"
      >
        <IconButton aria-label="Cart" className={classes.totalItems}>
          <Badge
            badgeContent={cart.reduce(
              (totalItems, cartItem) => totalItems + Number(cartItem.quantity),
              0
            )}
            color="secondary"
            classes={{ badge: classes.badge }}
          >
            <ShoppingCartIcon className={classes.light} />
          </Badge>
        </IconButton>
      </Tooltip>
      <SwipeableDrawer
        anchor="right"
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        <CartComponentContent {...props} />
      </SwipeableDrawer>
    </div>
  )
}
