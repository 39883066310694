import React from 'react'
import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    justifyItems: 'center',
    gap: '20px',
  },
  select: {
    gridColumn: '-1/1',
    margin: '0 10%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
  },
}))

export default function DateAndPlaceStep2(props) {
  const classes = useStyles()
  const {
    values,
    sourceRentalPlace,
    startDate,
    endDate,
    activeStep,
    handleBack,
    isLastStep,
  } = props

  return (
    <>
      <div className={classes.grid}>
        <div className={classes.select}>
          <Typography variant="subtitle1" gutterBottom>
            Miejsce odbioru
          </Typography>
          <Typography variant="h5">{sourceRentalPlace?.name}</Typography>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Kiedy odbiór
          </Typography>
          <Typography variant="h5">
            {moment(startDate).format('DD.MM.YYYY')}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            Kiedy zwrot
          </Typography>
          <Typography variant="h5">
            {moment(endDate).format('DD.MM.YYYY')}
          </Typography>
        </div>
      </div>

      {activeStep !== 0 && !isLastStep && (
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            onClick={() => {
              handleBack(values)
            }}
          >
            Cofnij
          </Button>
        </div>
      )}
    </>
  )
}
