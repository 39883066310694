import { tokenVerify } from './tokenVerify';
import { store } from './../_store';
export var authHeader = function () {
    var _a;
    var token = (_a = store.getState().auth.data) === null || _a === void 0 ? void 0 : _a.token;
    if (tokenVerify(token)) {
        return { Authorization: "Bearer " + token };
    }
    return {};
};
