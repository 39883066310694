import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Table from 'components/table/TableList'

import { authHeader } from '@ui/shared'
import { useFetch } from '@ui/shared'
import Typography from '@material-ui/core/Typography'

import { DriveEta, ErrorOutline } from '@material-ui/icons'

const mainData = [
  {
    name: 'ID',
    prop: 'id',
  },
  {
    name: <DriveEta />,
    prop: 'hiredItems.length',
  },
  {
    name: 'OD',
    prop: 'startDate',
  },
  {
    name: 'DO',
    prop: 'endDate',
  },

  {
    name: <ErrorOutline />,
    prop: 'hiredItems',
  },
]
const expandData = []

export const List = (link) => {
  const auth = useSelector((state) => state.auth)
  const profile = useSelector((state) => state.user.profile?.carCustomer)
  const personalDetails = useSelector(
    (state) => state.user.profile?.personalDetails
  )

  const endPoint =
    profile && personalDetails
      ? `${process.env.REACT_APP_API_ENTRYPOINT}/car_customers/${profile.id}/hires`
      : null

  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  return (
    <>
      <Typography component="h1" variant="h5" align="center" gutterBottom>
        Moje najmy
      </Typography>

      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        expandData={expandData}
        dataLength={res.state.data?.totalItems || 0}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </>
  )
}
