import React from 'react'

import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'

import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
import { notification } from '@ui/shared'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinner: {
    height: '24px',
    animationName: '$spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  formError: {
    display: 'block',
    color: 'red',
  },
}))

export const Activate = () => {
  const classes = useStyles()
  const history = useHistory()
  const isPassword = history.location.pathname.includes(
    'startResettingPassword'
  )
  const initialState = {
    password: '',
    email: '',
    isSubmitting: false,
    errorMessage: null,
  }

  const [data, setData] = React.useState(initialState)

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}${history.location.pathname}`,
      {
        method: isPassword ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: isPassword
          ? JSON.stringify({
              email: data.email,
            })
          : JSON.stringify({ password: data.password }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then(() => {
        if (history.location.pathname.includes('startResettingPassword')) {
          notification(
            'success',
            'Instrukcje zmiany hasła zostały wysłane na Twojego maila',
            'Sprawdź maila'
          )
        } else {
          notification('success', 'Zaloguj się', 'Aktywowano')
        }
        history.push('/')
      })
      .catch((errorPromise) => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: errorPromise.message || errorPromise.statusText,
        })

        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(`${e.propertyPath} : ${e.message}`)
            })

            errors = errors.join('\r\n')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }
  return (
    <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {history.location.pathname.includes('startResettingPassword') && (
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={data.email}
              onChange={handleInputChange}
            />
          )}
          {(history.location.pathname.includes('resetPassword') ||
            history.location.pathname.includes('activateCarCustomer')) && (
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
              value={data.password}
              onChange={handleInputChange}
            />
          )}
        </Grid>
      </Grid>
      <Button
        disabled={data.isSubmitting}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        {data.isSubmitting ? (
          <img
            className={classes.spinner}
            src="/images/logo.png"
            alt="loading icon"
          />
        ) : history.location.pathname.includes('activateCarCustomer') ? (
          'Aktywuj'
        ) : (
          'Zmień hasło'
        )}
      </Button>
    </form>
  )
}
