var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { authHeader } from './authHeader';
import { handleResponse as defaultHandleResponse } from './handleResponse';
export var fetchData = function (url, method, request, handleResponse, absoluteUrl) {
    if (method === void 0) { method = 'GET'; }
    if (request === void 0) { request = {}; }
    if (handleResponse === void 0) { handleResponse = null; }
    if (absoluteUrl === void 0) { absoluteUrl = false; }
    return fetch(absoluteUrl ? url : "" + process.env.REACT_APP_API_ENTRYPOINT + url, __assign({ method: method, headers: __assign({ accept: 'application/json', 'Content-Type': 'application/json' }, authHeader()) }, request)).then(handleResponse || defaultHandleResponse);
};
