import * as React from 'react'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Button, MenuItem } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { useSelector, useDispatch } from 'react-redux'

import { authHeader } from '@ui/shared'
import { useFetch } from '@ui/shared'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    gap: '20px',
  },
  dateWrap: {
    justifySelf: 'center',
  },
  select: {
    gridColumn: '-1/1',
    margin: '0 10%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}))

const categoriesEndPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/car_rental_places`

export default function DateAndPlaceStep1(props) {
  const classes = useStyles()

  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const categoriesRes = useFetch(categoriesEndPoint, options, args)

  const {
    sourceRentalPlace,
    startDate,
    endDate,
    activeStep,
    isLastStep,
    handleBack,
    handleNext,
  } = props

  return (
    <div>
      <Formik
        initialValues={{
          sourceRentalPlace,
          startDate,
          endDate,
        }}
        validationSchema={Yup.object({
          sourceRentalPlace: Yup.string().required('Required'),
          startDate: Yup.date()
            .required('Required')
            .default(() => new Date()),
          endDate: Yup.date()
            .required('Required')

            .when(
              'startDate',
              (startDate, schema) => startDate && schema.min(startDate)
            ),
        })}
      >
        {({
          submitForm,
          validateForm,
          setTouched,
          isSubmitting,

          values,
          setFieldValue,
          errors,
        }) => (
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Form className={classes.grid}>
                <Field
                  className={classes.select}
                  component={TextField}
                  type="text"
                  name="sourceRentalPlace"
                  label="Miejsce odbioru"
                  select
                  variant="standard"
                  helperText="Wybierz"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  SelectProps={{ renderValue: (value) => value.name }}
                >
                  {categoriesRes.state.data &&
                    categoriesRes.state.data.content?.map((category) => (
                      <MenuItem key={category.id} value={category}>
                        {category.name}
                      </MenuItem>
                    ))}
                </Field>
                <KeyboardDatePicker
                  className={classes.dateWrap}
                  format="dd.MM.yyyy"
                  label="Kiedy odbiór"
                  value={values.startDate}
                  onChange={(value) => setFieldValue('startDate', value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={errors?.startDate && true}
                  helperText={errors?.startDate}
                />
                <KeyboardDatePicker
                  className={classes.dateWrap}
                  format="dd.MM.yyyy"
                  label="Kiedy zwrot"
                  value={values.endDate}
                  onChange={(value) => setFieldValue('endDate', value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={errors?.endDate && true}
                  helperText={errors?.endDate}
                />
              </Form>
            </MuiPickersUtilsProvider>
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button
                  onClick={() => {
                    handleBack(values)
                  }}
                >
                  Cofnij
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={isSubmitting}
                onClick={() =>
                  validateForm().then((errors) => {
                    if (
                      Object.entries(errors).length === 0 &&
                      errors.constructor === Object
                    ) {
                      handleNext(values)
                    } else {
                      setTouched(errors)
                    }
                  })
                }
              >
                {isLastStep ? 'Zarezerwuj' : 'Dalej'}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}
