export const paymentType = [
  {
    title: 'Przelew',
    name: 'transfer',
  },
  {
    title: 'Gotówka',
    name: 'inCash',
  },
]

export const globalSalesValues = {
  vat: {
    '23%': {
      value: 0.23,
    },
    '8%': {
      value: 0.08,
    },
  },
  countType: ['szt', 'kg'],
}

export const invoicesType = ['Fakturę VAT', 'Fakturę Korygującą']

export const invoicesPrintType = [
  'Listę z podsumowaniem',
  'Każdą fakturę oddzielnie',
]

// jesli status ma tochange ustawione na true,
// to znaczy, że adminisstrato może ustawić
// ten status. W przeciwnym wypadku status
// ustawia się automatycznie, lub ustawia go
// klient w aplikacji mobilnej
export const hireStatus = [
  {
    type: 'hire',
    id: 'hire:Reserved',
    name: 'Rezerwacja',
    tochange: false,
  },
  {
    type: 'hire',
    id: 'hire:Accepted',
    name: 'Zarezerwowano',
    tochange: 'accept',
  },
  {
    type: 'hire',
    id: 'hire:Hire',
    name: 'Wynajem aktywny',
    tochange: 'start',
  },
  {
    type: 'hire',
    id: 'hire:Finished',
    name: 'Wynajem zakończony',
    tochange: 'finish',
  },
  {
    type: 'hire',
    id: 'hire:Cancelled',
    name: 'Wynajem anulowany',
    tochange: 'cancel',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Reserved',
    name: 'Rezerwacja',
    tochange: false,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Hire',
    name: 'Wynajem',
    tochange: 'start',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Breakdown',
    name: 'Awaria',
    tochange: false,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Return',
    name: 'Zwrot',
    tochange: true,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Repair',
    name: 'Naprawa',
    tochange: true,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Reactivate',
    name: 'Reaktywuj do najmu',
    tochange: 'reactivate',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Finished',
    name: 'Zakończony wynajem',
    tochange: 'finish',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Cancelled',
    name: 'Anulowanie',
    tochange: 'cancel',
  },
  {
    type: 'rentableItem',
    id: 'rentableItem:Archived',
    name: 'Archiwum',
    tochange: false,
  },
  {
    type: 'rentableItem',
    id: 'rentableItem:Active',
    name: 'Aktywny',
    tochange: false,
  },
  {
    type: 'rentableItem',
    id: 'rentableItem:Repair',
    name: 'Naprawa',
    tochange: false,
  },
]

export const invoiceStatusTrans = {
  notPaid: 'nie zapłacona',
  overdue: 'po terminie',
  paid: 'zapłacona',
}
