import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link, Grid, TextField } from '@material-ui/core';
import { validate } from 'email-validator';
import { useStyles } from './LoginStyles';
export var Login = function (_a) {
    var values = _a.values, handlers = _a.handlers, status = _a.status;
    var classes = useStyles();
    return (React.createElement("form", { className: classes.form, noValidate: true, onSubmit: handlers.handleSubmit },
        React.createElement(TextField, { error: status.submitted && !validate(values.email), helperText: status.submitted && !validate(values.email)
                ? 'Niepoprawny e-mail'
                : null, variant: "outlined", margin: "normal", required: true, fullWidth: true, id: "email", label: "Email", name: "email", autoComplete: "email", autoFocus: true, value: values.email, onChange: handlers.handleChange }),
        React.createElement(TextField, { error: status.submitted && !values.password, helperText: status.submitted && !values.password ? 'Hasło jest wymagane' : null, variant: "outlined", margin: "normal", required: true, fullWidth: true, name: "password", label: "Has\u0142o", type: "password", id: "password", autoComplete: "current-password", value: values.password, onChange: handlers.handleChange }),
        React.createElement(Button, { disabled: status.isLogging, type: "submit", fullWidth: true, variant: "contained", color: "primary", className: classes.submit }, status.isLogging ? (React.createElement("img", { className: classes.spinner, src: "/images/logo.png", alt: "loading icon" })) : ('Zaloguj się')),
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: true },
                React.createElement(Link, { href: "/security/startResettingPassword", variant: "body2" }, "Zapomnia\u0142e\u015B has\u0142a?")))));
};
Login.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
    }).isRequired,
    handlers: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    }).isRequired,
    status: PropTypes.shape({
        submitted: PropTypes.bool,
        isLogging: PropTypes.bool,
    }).isRequired,
};
