import { connect } from 'react-redux'
import { resetShop } from '@ui/shared'
import SuccessComponent from './../pages/Home/components/SuccessComponent'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  console.log('INSIDE DISPATCH TO RESET')
  return {
    resetShop: () => dispatch(resetShop()),
  }
}

const SuccessContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessComponent)

export default SuccessContainer
