import React from 'react'
import { StringField } from '@ui/shared'
import { MoneyField } from '@ui/shared'
import DateField from '../fields/DateField'
import StatusField from '../fields/StatusField'
import DaysLeft from '../fields/DaysLeft'
import DocsField from '../fields/DocsField'
import { hireStatus, invoiceStatusTrans } from 'dataconfig'
import InvoiceField from '../fields/InvoiceField'

export default function LeaseFieldFactory({
  fieldName,
  type,
  otherType,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  lease,
}) {
  switch (type) {
    case 'startDate':
      return <DateField fieldName={fieldName} value={value} />
    case 'endDate':
      return <DateField fieldName={fieldName} value={value} />

    case 'daysLeft':
      return <DaysLeft fieldName={fieldName} value={otherType} />

    case 'sourceRentalPlace.name':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'hireStatus':
      const val = hireStatus.find((el) => el.id === value)
      return (
        <StatusField
          fieldName={fieldName}
          selectableEntity={
            val !== undefined ? val : { id: value, name: value, type: 'hire' }
          }
        />
      )

    case 'cost':
      return (
        <MoneyField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'documents':
      return (
        <DocsField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'invoice.invoiceStatus':
      return (
        <>
          <InvoiceField
            fieldName={fieldName}
            value={
              invoiceStatusTrans[value] !== undefined
                ? invoiceStatusTrans[value]
                : value
            }
            lease={lease}
          />
          {lease.invoiceDocuments?.length > 0 && (
            <DocsField
              fieldName="Lista faktur"
              value={lease.invoiceDocuments}
              type={type}
              violations={violations}
              updatableParentEntity={updatableParentEntity}
              setUpdatableParentEntity={setUpdatableParentEntity}
              title="Lista wszystkich faktur powiązanych z najmem"
            />
          )}
        </>
      )

    default:
      return 'Nic'
  }
}
