var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getDefinitionProperties = function (definition, properties) {
    if (properties === void 0) { properties = null; }
    if (!properties) {
        return definition.properties;
    }
    return Object.keys(properties).reduce(function (obj, name) {
        var _a;
        return Object.assign(obj, (_a = {},
            _a[name] = __assign(__assign({}, definition.properties[name]), properties[name]),
            _a));
    }, {});
};
