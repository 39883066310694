var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { requestActions, authActions } from './../_actions';
import buildUrl from 'build-url';
import { isObjectEmpty } from './isObjectEmpty';
import { excludeInnerNullObjects } from './excludeInnerNullObjects';
import { notification } from './notification';
import { useHistory } from 'react-router-dom';
export var useFetch = function (url, options, args, queryParams) {
    if (queryParams === void 0) { queryParams = {}; }
    var history = useHistory();
    var dispatch = useDispatch();
    var _a = useState({
        isLoading: false,
        data: null,
        error: null,
        dataLength: null,
    }), state = _a[0], setState = _a[1];
    useEffect(function () {
        var abortController = new AbortController();
        var optionsNew = __assign(__assign({}, options), { signal: abortController.signal });
        if (Object.keys(queryParams).indexOf('refresh')) {
            delete queryParams.refresh;
        }
        var urlNew = buildUrl(url, {
            queryParams: excludeInnerNullObjects(queryParams),
        });
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, data_1, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dispatch(requestActions.start(urlNew));
                        setState(function (state) { return (__assign(__assign({}, state), { isLoading: true })); });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch(urlNew, optionsNew)];
                    case 2:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 3:
                        data_1 = _a.sent();
                        if ((data_1 === null || data_1 === void 0 ? void 0 : data_1.detail) === 'Musisz wypełnić dane osobowe!') {
                            notification('error', 'Musisz wypełnić dane osobowe!', 'Błąd', 2000);
                            setTimeout(function () {
                                history.push('/settings/profile');
                            }, 2000);
                        }
                        else if (res.status === 401 || res.status === 403) {
                            dispatch(authActions.logout());
                            notification('error', 'Brak uprawnień', 'Błąd');
                        }
                        else {
                            dispatch(requestActions.success());
                            setState(function (state) { return (__assign(__assign({}, state), { data: data_1, dataLength: data_1.length, isLoading: false })); });
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        if (!abortController.signal.aborted) {
                            dispatch(requestActions.failure(e_1));
                            setState(function (state) { return (__assign(__assign({}, state), { isLoading: false, error: true })); });
                        }
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        url !== null && fetchData();
        return function () {
            abortController.abort();
        };
    }, isObjectEmpty(queryParams) ? args : __spreadArrays(args, [url, queryParams]));
    return { state: state, setState: setState };
};
