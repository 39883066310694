import React from 'react'

export default function InvoiceField({ fieldName, lease, value }) {
  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName}`}</b>
      </div>
      <div>
        {value}
        {value === null && lease.cost > 0 && 'Oczekuje na wystawienie'}
      </div>
    </React.Fragment>
  )
}
