import { tokenVerify } from './tokenVerify';
export var isLogged = function (dispatch, restore, logout) {
    var token = localStorage.getItem('token');
    var isLogged = tokenVerify(token);
    if (isLogged) {
        dispatch(restore(token));
    }
    else if (isLogged === false) {
        dispatch(logout());
    }
};
