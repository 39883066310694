import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

function SuccessComponent(props) {
  const { resetShop } = props
  return (
    <div>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ marginTop: '20px' }}
      >
        <Typography variant="display3" color="primary" gutterBottom>
          Transakcja powiodła się!
        </Typography>

        <Button component={Link} to="/" onClick={resetShop}>
          Powrót
        </Button>
      </Grid>
    </div>
  )
}

export default SuccessComponent
