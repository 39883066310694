import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import reducers from './../_reducers';
import rootSaga from './../_sagas';
var sagaMiddleware = createSagaMiddleware();
export var configureStore = function () {
    var store = createStore(reducers, applyMiddleware(sagaMiddleware, thunkMiddleware));
    sagaMiddleware.run(rootSaga);
    return store;
};
